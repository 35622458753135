import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/configs/reset.css';
import '../src/configs/global.css';
import '../src/configs/magic-check.css';
import App from './App';
import '../src/configs/ipad.css';
import '../src/configs/mobile.css';
import reportWebVitals from './reportWebVitals';
import {RoleProvider} from "./context/RoleContext";

import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// @ts-ignore
root.render(
  <React.StrictMode>
    <RoleProvider>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </RoleProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


