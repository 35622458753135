import React, {useEffect, useState} from 'react'
import { MicrosoftTenantConnectionSettingProps } from './MicrosoftTenantConnectionSetting.props'
import './MicrosoftTenantConnectionSetting.styles.css';
import {Box, IconButton, Tooltip, Button, styled} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {SyncedStatus} from "../../atoms/SyncedStatus";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import {Modal} from "../../atoms/Modal";
import {useTranslation} from "react-i18next";
import {updateSetting} from "../../../api/emailTriage";

const OutlineButton = styled(Button)(({ theme }) => ({
    color: '#345C8B',
    borderColor: '#CCCFCF',
    marginTop: theme.spacing(2),
    minHeight: "40px",
     "&:hover": {
        borderColor: theme.palette.border.main
    },
}));

export const MicrosoftTenantConnectionSetting: React.FC<MicrosoftTenantConnectionSettingProps> = ({
    payLoad = {},
    onBeforeDisconnect = () => {},
    onAfterDisconnected = () => {},
    onDisconnected = () => {}
}) => {
    const [tenantId, setTenantId] = useState(null)
    const [connectedFeatureInfo, setConnectedFeatureInfo] = useState<any>([])
    const [isOpenDisconnectTenantModal, setIsOpenDisconnectTenantModal] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        if(Object.keys(payLoad).length > 0){
            setTenantId(payLoad["tenantId"])
            setConnectedFeatureInfo(payLoad["featureListInfo"])
        }
    }, []);

    const renderDisconnectTenantContentModal = () => (
        <Box className={"modal-setting-disconnect-tenant-content"}>
            <p style={{margin: 0}}>When you disconnect, your Microsoft 365 account will also be logged out. Are you sure you want to disconnect your organisation's tenant from Phish Focus? </p>
            <Box className={"highlighted-content"}>
                <span>All created data will be <strong>removed.</strong></span>
                <span style={{margin: "8px 0"}}>Phish Clear or Block List feature enabled will be <strong>disabled.</strong></span>
                <span>Automation Rules with Phish Clear or Block List enabled will be <strong>disabled.</strong></span>
            </Box>
        </Box>
    )

    const disconnectTenant = () => {
        onBeforeDisconnect()
        setIsOpenDisconnectTenantModal(false)

        updateSetting({
            task: 'disconnect_tenant'
        }).then((response) => {
            const data:any = response.data
            onDisconnected()
        }).catch(e => {
            setIsOpenDisconnectTenantModal(false)
            onAfterDisconnected()
        });
    }


    const handleConfirmDisconnectTenant = () => {
        disconnectTenant()
    }

    return Object.keys(payLoad).length > 0 ?
        <Box className={"tenant-connection-setting-wrapper"}>
            <Box className={'title-domain'}>
                <span>{t("tenantConnection.title")}</span>
                <Tooltip placement={"top"}
                         title={t("tenantConnection.title_tooltip")}
                         componentsProps={{
                             tooltip: {
                                 sx: {
                                     width: "auto",
                                     maxWidth: "700px",
                                     fontWeight: 600,
                                     padding: "8px",
                                     position: "relative",
                                     left: "40px"
                                 },
                             },
                         }}
                >
                    <IconButton className='info-action'><HelpIcon fontSize={'small'}
                                                                  htmlColor={"#193051"}/></IconButton>
                </Tooltip>
            </Box>
            {
                tenantId ? <Box className={"setting-ms-tenant-connection-content"}>
                    <Box className={"tenant-connection-table"}>
                        <Box className={"title-side"}>
                            <span>{t("tenantConnection.tenant_table_subtitle")}</span>
                            {/*<SyncedStatus value={"Connected"} icon={<CheckCircleIcon />} valueColor={"#008545"}/>*/}
                        </Box>
                        <Box className={'content-side'}>
                            <Box className={'connected-tenant-info'} >
                                <span >Connected organisation tenant ID:</span>
                                <span style={{fontWeight: 600, display: 'block', marginTop: '4px'}}>{tenantId}</span>
                            </Box>
                            <OutlineButton variant="outlined" className={"settingDisconnectedTenantBtn"} onClick={() => setIsOpenDisconnectTenantModal(true)}>{t("tenantConnection.disconnect_tenant")}</OutlineButton>
                        </Box>
                    </Box>
                    <Box className={"features-connected-to-tenant-table"}>
                        <Box className={"title-side"}>
                            <span>{t("tenantConnection.feature_table_subtitle")}</span>
                        </Box>
                        <Box className={'content-side'}>
                            {
                                connectedFeatureInfo.map((item: any, index: number) => {
                                    return <Box key={`info_record_${index}`} className={"info-record"} sx={{borderTop: index === 0 ? "" : "1px solid #E0E0E0"}}>
                                        <Box className={"feature-title"}>{item.title}<strong>{" :"}</strong></Box>
                                        <Box className={"connection-status"}>
                                            <SyncedStatus   value={item.connectionStatus ? "Connected" : "Not connected"}
                                                            icon={item.connectionStatus ? <CheckCircleIcon /> : <TimerOffOutlinedIcon htmlColor={'#434F4F'}/>}
                                                            valueColor={item.connectionStatus ? "#008545" : "#434F4F"} />
                                        </Box>
                                    </Box>
                                })
                            }
                        </Box>
                    </Box>
                </Box> : <Box style={{maxWidth: "1000px", margin: "0"}} dangerouslySetInnerHTML={{__html: t("tenantConnection.no_tenant_connected")}}></Box>
            }

            <Modal idModal="modal-setting-disconnect-tenant"
                   openDialog={isOpenDisconnectTenantModal}
                   variant={"delete"}
                   title={"Remove account"}
                   actionAgree={() => handleConfirmDisconnectTenant()}
                   actionCancel={() => setIsOpenDisconnectTenantModal(false)}
                   textAgree={"Disconnect"}
                   onClose={() => setIsOpenDisconnectTenantModal(false)}
                   children={renderDisconnectTenantContentModal()}
            />
        </Box> : <></>

}
