import React, {useEffect, useState} from 'react';
import {HomeProps} from './Home.props';
import './Home.styles.css';

import moment from "moment-timezone";
import {getStaticReportDashboard, getTreeMap, getBottomCards, getBottomCardByMonths} from "../../api/emailTriage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Fade, TextField, Typography, ThemeProvider, createTheme, useTheme} from '@mui/material';
import Stack from '@mui/material/Stack';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as Calendar } from "../../assets/images/calendar.svg";
// import appConfig from "../../configs/appConfig.json";

import {DateRangePicker} from "../../components/atoms/DateRangePicker";
import {MiniDashboard} from "../../components/organisms/MiniDashboard"
import {MiddleDashboard} from "../../components/organisms/MiddleDashboard";
import {BottomDashboard} from "../../components/organisms/BottomDashboard";

import ChangeWithZone from "../../hoc/ChangeWithZone";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {MonthPicker} from "../../components/atoms/MonthPicker";


const getMonthName = (monthNumber: any) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'long' });
}

const HomeFull: React.FC<HomeProps> = () => {
  const defaultTheme = useTheme()
  // @ts-ignore
  const customTheme = createTheme({
    ...defaultTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 820,
        md: 1366,
        lg: 1536,
        xl: 2200,
      }
    }
  })
  const [isShowLoading , setShowLoading] = useState(true);
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DD')));
  const [defaultFilter, setDefaultFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const defaultMiniDashboardData =[
    {
      'name': 'Reported',
      'months': null,
    },
    {
      'name': 'Automatically Resolved',
      'months': null
    },
    {
      'name': 'Manually Resolved',
      'months': null
    },
    {
      'name': 'Unresolved',
      'months': null
    }
  ]
  let tz_name = 'Etc/UTC';
  const localStorageUserData = localStorage.getItem('userData');
  if(localStorageUserData) {
    const parseUserData = JSON.parse(localStorageUserData)
    tz_name = parseUserData["tz_name"]
  }
  
  const updateDefaultFilter =(currentDate: any) =>{
    let monthNames = [];
    let result: { startOfMonth: any, endOfMonth: any }[] = [];
    for (let i = 5; i >= 0; i--) {
    
      const date = currentDate.clone().subtract(i, 'months');
    
      const startOfMonth = date.clone().startOf('month').tz(tz_name).utc().format('YYYY-MM-DD HH:mm:ss');
    
      const endOfMonth = date.clone().endOf('month').tz(tz_name).utc().format('YYYY-MM-DD HH:mm:ss');
    
      result.push({ startOfMonth, endOfMonth });
      const monthName = date.format('MMM YY');
      monthNames.unshift(monthName);
    }
  
    setDefaultFilter({
      arrMonths : monthNames,
      arrDates: result.reverse()
    });
  }
  
  const [middleTreeMap, setMiddleTreeMap] = useState<any>(null)
  const [bottomCard, setBottomCard] = useState<any>(null)
  const [bottomCardByMonths, setBottomCardByMonths] = useState<any>(null)
  
  const [miniDashboardData , setMiniDashboardData ] = useState<any>(defaultMiniDashboardData);
  const [donutReportedData, setDonutReportedData] =  useState<any>(null)
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const getDashBoard = (params: any) => {
    setShowLoading(true);
    setMiniDashboardData(defaultMiniDashboardData);
    setDonutReportedData(null);
    return getStaticReportDashboard(params).then((result) => {
      const a = result.data;
      setMiniDashboardData([{
        'name': 'Reported Messages',
        'months': a.month
      },
        {
          'name': 'Automatically Resolved',
          'months': a.auto
        },
        {
          'name': 'Manually Resolved',
          'months': a.manual
        },
        {
          'name': 'Unresolved',
          'months': a.Unresovled
        }])
      setDonutReportedData({
        "data":[a.auto[a.currentMonth], a.manual[a.currentMonth], a.Unresovled[a.currentMonth]],
        "currentMonth": getMonthName(moment().utc().format('MM'))
      })
      setShowLoading(false)
      return a;
    }).catch((e) => console.log(e));
  };

  const getMiddleTreeMap = (params: any) => {
    setShowLoading(true);
    setMiddleTreeMap(null)
    return getTreeMap(params).then((result) => {
      const a = result.data;
      setMiddleTreeMap(a)
      setShowLoading(false);
      return a;
    }).catch((e) => console.log(e));
  };

  const getBottomCard = (params: any) => {
    setShowLoading(true);
    setBottomCard(null)
    return getBottomCards(params).then((result) => {
      const a = result.data;
      setBottomCard(a)
      setShowLoading(false);
      return a;
    }).catch((e) => console.log(e));
  };

  const getBottomCardByMonth = (params: any) => {
    setShowLoading(true);
    setBottomCardByMonths(null);
    return getBottomCardByMonths(params).then((result) => {
      const a = result.data;
      setBottomCardByMonths(a)
      setShowLoading(false);
      return a;
    }).catch((e) => console.log(e));
  };

  useEffect(() => {
    getDashBoard(defaultFilter);
    getMiddleTreeMap(defaultFilter);
    getBottomCard(defaultFilter);
    getBottomCardByMonth(defaultFilter)
  }, [defaultFilter]);
  
  useEffect(() => {
    updateDefaultFilter(moment().tz(tz_name))
  }, []);
  
  useEffect(() => {
    const yearMonth = dayjs(valueDate).format('YYYY-MM');
    updateDefaultFilter(moment(yearMonth+'-15').tz(tz_name))
  }, [valueDate]);
  
  // @ts-ignore
  return (
      <ThemeProvider theme={customTheme}>
        <Box
          className={'dashboard-wrapper'}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              margin: '16px',
            }}
          >
            <Typography
              align={'center'}
              sx={{
                fontFamily: 'Righteous',
                fontWeight: 400,
                fontSize: '28px',
                color: '#193051',
                textTransform: 'capitalize'
              }}
            >
              Dashboard
            </Typography>

            {/*month picker*/}
            <span
                onClick={handleClick}
                className={`date-label-dashboard ${ open ? 'active' : ''}`}>{<strong> MONTH: </strong>} &nbsp;
              <span style={{fontWeight: 500,}} className='date-value'>
                {dayjs(valueDate).format('MMM-YYYY')}
              </span> &nbsp;
              {<Calendar style={{}} fill={'#345C8B'}/> }
            </span>
            <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                transformOrigin={{
                  vertical: 0,
                  horizontal: 97,
                }}
            >
              <MonthPicker
                  handleChange={(newValue: any)=> {setValueDate(newValue); handleClose()}}
                  handleCancel={() => handleClose()}
                  valueDate={dayjs(valueDate)}
                  timeZoneName = {tz_name}
              />
            </Menu>
          </Stack>
          <Grid item xs={12} mx={2} mb={2}>
            <Grid item xs={12}>
              <MiniDashboard
                miniDashboardData={miniDashboardData}
                isLoading={isShowLoading}/>
            </Grid>
            <Grid item xs={12} >
              <MiddleDashboard
                isLoading={isShowLoading}
                reportedMessagesData={miniDashboardData}
                donutReportedData={donutReportedData}
                treeMapData = {middleTreeMap}
              />
            </Grid>
            <Grid item xs={12}>
              <BottomDashboard
                isLoading={isShowLoading}
                bottomCard={bottomCard}
                bottomCardByMonths={bottomCardByMonths}
              />
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>

  );
};

export const Home = ChangeWithZone(HomeFull);
