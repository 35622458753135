import React, {Fragment, useContext, useEffect, useState, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes, Navigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {Home} from './pages/Home';
import {Inbox} from './pages/Inbox';
import {AutomationRules} from './pages/AutomationRules';
import {Settings} from './pages/Settings';
import {Authen} from './pages/Authen/Authen';
import {Logout} from './pages/Logout/Logout';
import {Callback} from './pages/Callback';
import {Header} from './components/organisms/Header';
import {Footer} from './components/organisms/Footer';
import {PageNotFound} from './pages/PageNotFound/PageNotFound';
import {LeftNavButton} from './components/molecules/LeftNavButton';
import {ADMIN_ROLE, CONSTANTS, SETTING_BLOCK_LIST, SETTING_TYPE_AUTOMATION_RULE} from './constants/contants';
import {GlobalContext, GlobalProvider} from "./context/GlobalState"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LightTheme from "./theme/light";
import {isMobileOnly} from "react-device-detect";
import IdleTimeOutHandler from './components/utils/IdleTimeOutHandler';
import {AutomationRuleNewDetails} from "./pages/AutomationRuleNewDetails";
import {useRoleContext} from "./context/RoleContext";
import {LoadingPage} from "./components/organisms/LoadingPage";
import PrivateRoute from "./components/utils/PrivateRoute";
import DownloadToken from "./components/utils/DownloadToken";
import {setMenuRoute} from "./routing/routes";
// MSAL login Azure
import {MsalProvider} from "@azure/msal-react";
import {Configuration, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./api/azure";
import {BlockList} from "./pages/BlockList";
import {handleSpinEmailDetailTabs} from "./utils/common";
import {BlockListActivityLogs} from "./pages/BlockListActivityLogs";
import {AuthenAzure} from "./pages/AuthenAzure";

const queryClient = new QueryClient();
const theme = createTheme(LightTheme);
export const msalInstance = new PublicClientApplication(msalConfig as Configuration);

const App: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn')=='true')
  const [isArchivedEmail, setIsArchivedEmail] = useState(false)
  const [settingStatus, setSettingStatus] = useState()
  const [loadingPage, setLoadingPage] = useState(true)
  const { setting, setSetting } = useRoleContext()

  const currentPath = window.location.pathname

  const checkLeftNav = () => {

    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'));
    },200)

    if(isOpen) {
      document?.getElementById('root')?.classList.remove('menu-opened')
    } else {
      document?.getElementById('root')?.classList.add('menu-opened')
    }

    setOpen(!isOpen);

    handleSpinEmailDetailTabs({'transitionProperty': 'width', 'transitionDuration': '0.5s'})
  }

  const onZoneChanged = () => {

  }

  useEffect(() => {
    setSetting({
      ...setting,
      // [SETTING_TYPE_AUTOMATION_RULE]: settingStatus,
      // [SETTING_BLOCK_LIST]: settingStatus
    })
  }, [settingStatus])

  const data = setMenuRoute(setting)

  const[isActive, setIsActive]=useState(true)

  const loadingRoute = loadingPage ?
    <Route path='*' element={<LoadingPage open={true}/>}/> : null

  const redirectHomePage = <Route path='*' element={<Navigate to='/'/>}/>

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <Router>
              <GlobalProvider onZoneChanged={onZoneChanged}>
                {currentPath.includes('/download/') ?
                  <Routes>
                    <Route path='/download/:token' element={<DownloadToken/>} />
                  </Routes> :
                  (
                isLoggedIn ?
                  <div>
                    <div className={'app-root'}>
                        <IdleTimeOutHandler onActive={()=>{setIsActive(true)}} onIdle={()=>{setIsActive(false)}}/>
                        <Header onClick={checkLeftNav}/>
                        <div className='left-content' >
                          <LeftNavButton setSettingStatus={setSettingStatus} setLoadingPage={setLoadingPage} setOpenNav={checkLeftNav} isOpen={isOpen} leftNavBtnArr={data} isArchivedEmail={isArchivedEmail}/>
                        </div>

                        <div className={isOpen ? "right-content-opened": "right-content-close"}>
                          <div className='page-content'>
                            <div className='right-content'>
                             <Routes>
                               <Route element={<PrivateRoute/>}>
                               <Route path='/' element={<Home/>}/>
                               <Route path='/dashboard' element={<Home/>}/>
                               <Route path='/inbox' element={<Inbox isOpen={isOpen} setIsArchivedEmail={setIsArchivedEmail}/>}/>
                               <Route path='/archived' element={<Inbox isOpen={isOpen} isArchived={true} setIsArchivedEmail={setIsArchivedEmail}/>}/>
                               <Route path='/inbox/:id' element={<Inbox isOpen={isOpen} setIsArchivedEmail={setIsArchivedEmail}/>}/>
                               <Route path='/archived/:id' element={<Inbox isOpen={isOpen} isArchived={true} setIsArchivedEmail={setIsArchivedEmail}/>}/>
                               {loadingPage ? loadingRoute :
                                 setting?.automation_rule === true && !loadingPage ?
                                   <Route path='/automation-rules' element={<AutomationRules/>}/> :
                                   currentPath === '/automation-rules' ? redirectHomePage : <Route path={"*"} element={<PageNotFound/>}/>}
                               {loadingPage ? loadingRoute :
                                 setting?.automation_rule === true && !loadingPage ?
                                   <Route path='/automation-rules/:id' element={<AutomationRuleNewDetails/>}/> :
                                   currentPath === '/automation-rules' ? redirectHomePage : <Route path={"*"} element={<PageNotFound/>}/>}
                               {loadingPage ? loadingRoute :
                                   setting?.block_list === true && !loadingPage ?
                                   <Route path='/block-list' element={<BlockList/>}/> :
                                   currentPath === '/block-list' ? redirectHomePage : <Route path={"*"} element={<PageNotFound/>}/>}
                                {loadingPage ? loadingRoute :
                                   setting?.block_list === true && !loadingPage ?
                                   <Route path='/block-list/activity-logs' element={<BlockListActivityLogs/>}/> :
                                   currentPath === '/block-list/activity-logs' ? redirectHomePage : <Route path={"*"} element={<PageNotFound/>}/>}
                               <Route path='/settings' element={<Settings/>}/>
                               <Route path='/callback' element={<Callback/>}/>
                               <Route path='/logout' element={<Logout/>}/>
                               <Route path={"*"} element={<PageNotFound/>}/>
                               <Route path='/oauth/redirect' element={<AuthenAzure/>} />
                               </Route>
                             </Routes>
                            </div>
                          </div>
                        </div>
                        <LoadingPage open={loadingPage}/>

                      {!isMobileOnly ? <Footer/> : <></>}
                    </div>
                  </div>: <Routes>
                    <Route path='/dashboard' element={<Logout />} />
                    <Route path='/inbox' element={<Logout />} />
                    <Route path='/inbox/:id' element={<Logout />} />
                    <Route path='/archived/:id' element={<Logout />} />
                    <Route path='/archived' element={<Logout />} />
                    <Route path='/automation-rules' element={<Logout/>} />
                    <Route path='/automation-rules/:id' element={<Logout />} />
                    <Route path='/block-list' element={<Logout />} />
                    <Route path='/block-list/activity-logs' element={<Logout />} />
                    <Route path='/settings' element={<Logout />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path={"*"} element={<PageNotFound/>} />
                    <Route path='/' element={<Logout />} />
                    <Route path='/callback' element={<Callback/>} />
                    <Route path='/oauth' element={<Authen/>} />
                    <Route path='/oauth/redirect' element={<Logout/>} />
                  </Routes>
                  )}
              </GlobalProvider>
            </Router>
          </MsalProvider>
        </QueryClientProvider>
      </ThemeProvider>

    </Fragment>
  );
};

export default App;
