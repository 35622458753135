import React from 'react';
import './RedirectTo365.styles.css';
import {RedirectTo365Props} from './RedirectTo365.props';
import ConfirmDialog from "../../molecules/ConfirmDialog/ConfirmDialog";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import {useTranslation} from "react-i18next";

const RedirectTo365: React.FC<RedirectTo365Props> = ({
     show,
     onCancel,
     onSubmit,
     children,
     nameTitle
 }) => {
  const {t} = useTranslation();
  const noteComponent = () => {
    let content = t("RedirectTo365.note_content")
    return <Box className={'automation_rule_details_connect-ms365-description'}>
     <Box sx={{margin: '8px'}} className={'wrapper-title'}>
        <ErrorIcon />
        <Box className={'connect-ms365-description-title'}>
          <p className={'text-title'}>PLEASE NOTE!</p>
          <p style={{margin: 0}}>{content}</p>
        </Box>
      </Box>
    </Box>
  }

    return (
      <ConfirmDialog showDialog={show} onCancel={onCancel} onSubmit={onSubmit}>
       <span dangerouslySetInnerHTML={{__html: t("RedirectTo365.content",{nameTitle:nameTitle})}}></span>
        {noteComponent()}
      </ConfirmDialog>
    )
}
export default RedirectTo365;