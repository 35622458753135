import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import './RedirectTo365WithStepper.styles.css';
import {RedirectTo365WithStepperProps} from './RedirectTo365WithStepper.props';
import ConfirmDialog from "../../molecules/ConfirmDialog/ConfirmDialog";
import {VerticalLinearStepper} from "../../molecules/Stepper";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import {useTranslation} from "react-i18next";
const RedirectTo365WithStepper: React.FC<RedirectTo365WithStepperProps> = ({
     show ,
     onCancel,
     onSubmit,
     setActiveStep,
     activeStep,
 }) => {
  const {t} = useTranslation();
  const contentDefault = t("RedirectTo365Stepper.content")
  const [content, setContent]=useState(contentDefault)
  const handleSubmit =(valueStep:number)=>{
    if(valueStep === 0){
      setContent(t("RedirectTo365Stepper.content_phish_clear"))
    }
    onSubmit && onSubmit(valueStep);
  }

  useEffect(()=>{
    if(activeStep === 1){
      setContent(t("RedirectTo365Stepper.content_phish_clear"))
    }
  },[activeStep])

  const noteComponent = () => {
    let content = t("RedirectTo365.note_content")
    return <Box sx={{marginTop:'32px'}} className={'automation_rule_details_connect-ms365-description'}>
      {/*<Box sx={{width: '4px', backgroundColor: '#467599', borderRadius: '4px 0 0 4px'}} />*/}
      <Box sx={{margin: '8px'}} className={'wrapper-title'}>
        <ErrorIcon data-testid="ErrorIcon365Stepper"/>
        <Box className={'connect-ms365-description-title'}>
          <p className={'text-title'}>PLEASE NOTE!</p>
          <p style={{margin: 0}}>{content}</p>
        </Box>
      </Box>
    </Box>
  }

    return (
      <ConfirmDialog  showDialog={show} onCancel={onCancel} isHidden>
        <p style={{margin: '0 0 24px 0'}} dangerouslySetInnerHTML={{__html:content}} ></p>
        <VerticalLinearStepper activeStep={activeStep} setActiveStep={setActiveStep} submit={(step)=>handleSubmit(step)} />
        {noteComponent()}
      </ConfirmDialog>
    )
}
export default RedirectTo365WithStepper;