import React from 'react';
import {ThreatScoreTooltipProps} from './ThreatScoreTooltip.props';
import './ThreatScoreTooltip.styles.css';
import Box from '@mui/material/Box';
import {RadialGraph} from "../RadialGraph";

export const ThreatScoreTooltip: React.FC<ThreatScoreTooltipProps> = ({

}) => {
    let threatScoreData:any = [
        {color: "#DB071E", value: 100, title: "Threat detected", description: "High confidence in threat detected."},
        {color: "#FFA000", value: 50, title: "Undetermined", description: "Unknown threat. Or email scanning failed due to multiple factors."},
        {color: "#03A9F5", value: 0, title: "No threat", description: "No threat detected."},
        {name: "ANALYSING...", title: "Scanning in progress. No threat score yet."}
    ]

    return (
        <Box className={"threat-score-tooltip-wrapper"} sx={{width: '480px'}}>
            <Box className={"title"} sx={{height: "32px", display: 'flex', alignItems: "center"}}>Threat Score</Box>
            <Box className={"content"}>
                {
                    threatScoreData.map((item:any) =>
                        <Box className={"threat-score-info"} >
                            {
                                item.name ?
                                    <>
                                        <Box sx={{fontWeight: 600, fontSize: "10px", color: '#4D5858'}}>{item.name}</Box>
                                        <Box sx={{fontWeight: 500, position: "absolute", left: "80px", right: 0}}>{item.title}</Box>
                                    </> :
                                    <>
                                        <Box sx={{paddingLeft: '5px',width: "40px", height: "40px"}} >
                                            <RadialGraph value={item.value} chartStyle={{stroke: 'unset'}} chartLayoutPosition={{left: 20}}/>
                                        </Box>
                                        <Box sx={{fontWeight: 500, position: "absolute", left: "80px", right: 0}}>
                                            {item.title}
                                            <span style={{display: "block", fontSize: "12px", color: "rgba(0, 0, 0, 0.60)"}}>{item.description}</span>
                                        </Box>
                                    </>
                            }
                        </Box>
                    )
                }
            </Box>
        </Box>
    )
};
