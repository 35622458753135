import React, {useRef, useEffect, useState} from 'react';

import { AnalysisBoxProps } from './AnalysisBox.props';
import './AnalysisBox.styles.css'
import Box from "@mui/material/Box";
import {AnalysisBoxItem} from "../AnalysisBoxItem";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Divider from "@mui/material/Divider";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export const AnalysisBox: React.FC<AnalysisBoxProps>= ({
    payload = {}
}) => {
    const eRef = useRef<HTMLDivElement>(null);
    const [hasScrollbar, setHasScrollbar] = useState<boolean>(false);

    useEffect(() => {
        const element = eRef.current;
        if (element){
            setHasScrollbar(element.scrollHeight > element.clientHeight)
        }
    }, [])

  const items:any = payload.items
  const theme = useTheme();

  let displayColor = ''
    let backgroundColor = 'var(--brand-colours-primary-seafoam-white, #FFF)'
    let borderColor = '1px solid var(--colour-plain-black-200, #CCCFCF)'
  let displayIcon:any = <ReportProblemIcon />
  switch (payload.priority) {
      case 'high': {
          displayColor = theme.palette.error.main
          if (items.length > 0){
              backgroundColor = "var(--colour-danger-50, #FFEAED)"
              borderColor = "1px solid var(--colour-danger-300, #ED6969)"
          }
          displayIcon = <ReportProblemIcon sx={{fontSize: '48px', color: displayColor}}/>
      } break;
      case 'moderate': {
          displayColor = theme.palette.warning.main
          if (items.length > 0){
              backgroundColor = "var(--colour-amber-50, #FFF8E1)"
              borderColor = "1px solid var(--colour-warning-800, #FCA910)"
          }
          displayIcon = <RemoveCircleIcon sx={{fontSize: '48px', color: displayColor}}/>
      } break;
      case 'low': {
          displayColor = theme.palette.info.main
          displayIcon = <></>
      } break;
  }

  return <Box sx={{p: 4, backgroundColor: backgroundColor, border: borderColor, pr: hasScrollbar ? "4px" : 4}} className={'analysis-box cat-' + payload.priority}>
      <div className={'analysis-box-header'} style={{maxWidth: hasScrollbar ? "calc(100% - 28px)" : "100%"}}>
          <div className={'analysis-box-total'}>{items.length || 0}</div>
          <div className={'analysis-box-title-wrapper'}>
              <Typography className={'analysis-box-title'} variant={'subtitle1'}>{payload.title}</Typography>
              <Typography className={'analysis-box-date'} variant={'body2'}>Date analysed: {payload.analysed_date}</Typography>
          </div>
          <div className={'analysis-box-icon'}>{displayIcon}</div>
      </div>
      <Divider sx={{background: displayColor, mt: 2, borderWidth: 1, maxWidth: hasScrollbar ? "calc(100% - 28px)" : "100%"}}/>
          {
              items.length > 0 ? (
                  <div ref={eRef} className={'analysis-box-content app-scrollbar'} style={{paddingRight: hasScrollbar ? "10px" : "0"}} >
                      {
                          items.map((data: any, index: any) => {
                              return (
                                  <AnalysisBoxItem key={index} payload={data} index={index+1}/>
                              );
                          })
                      }
                  </div>
              ) : (
                  <Box sx={{textAlign: 'center', py:3 }} className={'app-na'}>
                      <Typography sx={{
                          fontSize: "50px",
                          fontWeight: "600",
                          color: "#B3B8B8",
                          lineHeight: "100%"
                      }} variant={'h4'}>N/A</Typography>
                      <Typography variant={'body2'} sx={{
                          color: "#B3B8B8",
                          fontWeight: "600",
                      }}>{payload.not_found_message || 'Data not avaiable'}</Typography>
                  </Box>
              )
          }
  </Box>;
};
