import React, {useState, useEffect} from "react";
import {AutomationRuleNewDetailsProps} from "./AutomationRuleNewDetails.props";
import Box from "@mui/material/Box";
import {
    Checkbox,
    Drawer,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Switch,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import {PageHeader} from "../../components/organisms/PageHeader";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Modal} from "../../components/atoms/Modal";
import {isMobileOnly} from "react-device-detect";
import {SendMailModal} from "../../components/organisms/SendMailModal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import "./AutomationRuleNewDetails.styles.css";
import {
    authorizeToken,
    checkRuleNameDetail,
    getRuleDetail, getSettings,
    updatePhishClearAndBlockList,
    updateRuleDetail
} from "../../api/emailTriage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Toast} from "../../components/atoms/Toast";
import {conditionValues, status, priorities, categoríes} from "../../constants/contants";
import CheckIcon from '@mui/icons-material/Check';
import LeavePageDialog from '../../components/organisms/LeavePageDialog/LeavePageDialog';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import Grid from "@mui/material/Grid";
import ErrorIcon from '@mui/icons-material/Error';
import {LoadingPage} from "../../components/organisms/LoadingPage";
import Skeleton from "@mui/material/Skeleton";
import {AutomationRuleActionCard} from "../../components/molecules/AutomationRuleActionCard";
import {useTranslation} from "react-i18next";
import {CredentialCard} from "../../components/molecules/CredentialCard";
import ConfirmDialog from "../../components/molecules/ConfirmDialog/ConfirmDialog";
import {VerticalLinearStepper} from "../../components/molecules/Stepper";
import RedirectTo365WithStepper from "../../components/organisms/RedirectTo365WithStepper/RedirectTo365WithStepper";
import RedirectTo365 from "../../components/organisms/RedirectTo365/RedirectTo365";
import {getUserData} from "../../utils/common";

const automationRuleDetailLinks = [
    {
        name: "Automation Rules",
        href: "/automation-rules",
    },
    {
        name: "Rule Details",
    },
];
export const AutomationRuleNewDetails: React.FC<AutomationRuleNewDetailsProps> = () => {
    const defaultContent = `<p>Hello,</p><p> Please see the attached email that was reported through the Phriendly Phishing add-in for further investigation.</p><p>You can download the original email via this link {{download reported email}}. (This link will expire after 7 days)</p><p> Kind Regards,<br/>Security Team</p>`;
    // @ts-ignore
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
    const defaultParams: any = {
        subject: '',
        from: '',
        fromName: '',
        email_content: '',
        recipients: [],
        reportedBy: '',
        to: [],
        cc: [],
    }
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as {
        service?: string
        message?: string
        status?: boolean
        sourceId?: string
    };
    const {t} = useTranslation();
    const {id} = useParams();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [paramSendMail, setParamSendMail] = useState(defaultParams);
    const [sendMail, setSendMail] = useState(defaultParams);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [ruleDetail, setRuleDetail] = useState(null);
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [responseMail, setResponseMail] = useState(defaultParams);
    const [openDialogResponseMail, setOpenDialogResponseMail] = React.useState(false);
    const [paramResponseMail, setParamResponseMail] = useState(defaultParams);
    const [modalID, setModalID] = useState(0);
    const [timer, setTimer] = useState<any>(null);
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    // validate from name on send email modal
    const [validateFromName, setValidateFromName] = useState(false);
    const [validateToField, setValidateToField] = useState(false);
    const [validateEmailContent, setValidateEmailContent] = useState(false);
    const [defaultContentReporter, setDefaultContentReporter] = useState('');
    // useState show leavePage
    const [showLeaveDialog, setShowLeaveDialog] = useState<boolean>(false);
    const [flagHelperTextDescription, setFlagHelperTextDescription] = useState<boolean>(true);
    const [flagHelperTextRuleName, setFlagHelperTextRuleName] = useState<boolean>(true);
    const [showPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(showLeaveDialog);
    // valueCheckedName and updateValueChecked to check data only call api checkDetailName when change rule name
    const [valueCheckedName, setValueCheckedName] = useState('');
    const [updateValueChecked, setUpdateValueChecked] = useState();

    // validate user name on send email modal
    const [validateUserName, setValidateUserName] = useState(false);
    // validate user name on send email modal
    const [validateBaseSender, setValidateBaseSender] = useState(false);
    const [isShowRedirect365, setIsShowRedirect365] = useState(false);
    const [isShowRedirect365Stepper, setIsShowRedirect365Stepper] = useState(false);
    const [nameTitle, setNameTitle] = useState('');
    const [valueUpdate, setValueUpdate] = useState<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [autoBlockListData, setAutoBlockListData] = useState<any>({});
    const [autoPhishClearData, setAutoPhishClearData] = useState<any>({});

    function checkValueTimeOut(val: string) {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        return new Promise((resolve) => {
            const timers = setTimeout(async () => {
                const resValue = await checkRuleNameDetail({rule_name: val, rule_id: id});
                resolve(!resValue.data);
            }, 600);
            setTimer(timers);
        });
    }

    // validate form
    const validationSchema = yup.object({
            rule_name: yup
                .string()
                .required('This field is required')
                .test('rule_name', 'This name already exists. Please re-enter.', async (val) => {
                let valueChecked: any;
                    if(val !== valueCheckedName){
                        valueChecked = await checkValueTimeOut(val);
                        setUpdateValueChecked(valueChecked);
                        return valueChecked ? true : false;
                    }
                    return updateValueChecked ? true : false;
                }),
            description: yup
                .string()
                .required('This field is required'),
        }
    );

    const handleUpdate =(params:any)=>{
        if(formik.values.phish_clear && formik.values.block_list){
            if(!autoBlockListData.authenticated && !autoPhishClearData.authenticated){
                setIsShowRedirect365Stepper(true)
                setIsShowRedirect365(false)
            } else if (!autoBlockListData.authenticated || !autoPhishClearData.authenticated){
                setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(true)
                if(!autoBlockListData.authenticated) {
                    setNameTitle('Block List')
                } else{
                    setNameTitle('Phish Clear')
                }
            } else {
                setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(false)
                getUpdateNewDetail(params)
            }
        } else if(formik.values.phish_clear){
            setNameTitle('Phish Clear')
             if (!autoPhishClearData.authenticated){setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(true)
            } else {
                setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(false)
                getUpdateNewDetail(params)
            }
        } else if(formik.values.block_list) {
            setNameTitle('Block List')
            if (!autoBlockListData.authenticated) {
                setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(true)
            } else {
                setIsShowRedirect365Stepper(false)
                setIsShowRedirect365(false)
                getUpdateNewDetail(params)
            }
        } else {
            setIsShowRedirect365Stepper(false)
            setIsShowRedirect365(false)
            getUpdateNewDetail(params)
        }
    }
    const cancel365Stepper =() =>{
        setIsShowRedirect365Stepper(false)
        sessionStorage.removeItem('isShow365Stepper')
    }

    const cancel365 =() =>{
        setIsShowRedirect365(false)
    }

    const handleOnSubmit365Stepper =(step:number)=>{
        setShowLeaveDialog(false)
        setLoadingPage(true)
        setIsShowRedirect365Stepper(false)
        if(step === 0){
            sessionStorage.setItem('step365stepper', '1');
            LoginAzure({
                service: "auto_phish_clear",
                sourceId: id
            })
            sessionStorage.setItem('isShow365Stepper', 'true')
        }
        if(step === 1){
            setIsShowRedirect365Stepper(false)
            LoginAzure({
                service: "auto_block_list",
                sourceId: id
            })
            sessionStorage.removeItem('isShow365Stepper');
            sessionStorage.removeItem('step365stepper');
       }
        const params : any ={
            ...valueUpdate
        }
        delete params?.block_list;
        delete params?.phish_clear;
        getUpdateNewDetail(params)
    }
    const LoginAzure = (params:any) => {
        const currentUser:any = getUserData()
        const payload:any = {}

        payload["sourceId"] = params['sourceId']
        payload["service"] = params['service']
        payload["identity_action"] = "login"
        payload["app_id"] = process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID
        payload["redirect_uri"] = window.location.origin
        payload["user_id"] = currentUser['id']
        payload["user_role"] = currentUser['role']
        payload["user_email"] = currentUser['email']
        payload["user_name"] = currentUser['name']

        sessionStorage.setItem("auth_route", JSON.stringify({
            service: params['service'],
            id: params['sourceId']
        }))

        authorizeToken(payload).then((response) => {
            window.open(
              response.data.authorization_url,
              '_self', 'noopener,noreferrer'
            )
        })
          .catch(e => {
              console.log(e);
          })
    }

    const getUpdateNewDetail =(valueUpdate:any)=>{
        setLoadingPage(true)
        updateRuleDetail(valueUpdate).then(res => {
            if (res && res.status === 200) {
                getRuleDetailData();
                setModalID(modalID + 1)
                setToastValue({type: 'success', message: 'Saved successfully.', isOpen: true, toastId: modalID});
                setShowLeaveDialog(false);
            }
            setLoadingPage(false)
        }).catch(err => {
            setLoadingPage(false)
            setModalID(modalID + 1)
            setToastValue({type: 'error', message: 'Saved fail.', isOpen: true, toastId: modalID});
        });
    }

    const getUpdatePhishClearBlockList =()=>{
        setLoadingPage(true)
        getSettings({
            "task": "get_accounts_data"
        }).then(res => {
            if (res && res.status === 200) {
                setAutoBlockListData(res.data.auto_block_list)
                setAutoPhishClearData(res.data.auto_phish_clear)
            }
            setLoadingPage(false)
        }).catch(err => {
            setLoadingPage(false)
        });
    }

    const handleOnSubmit365 = () => {
        setShowLeaveDialog(false)
        const params : any ={
            ...valueUpdate
        }
        delete params?.block_list;
        delete params?.phish_clear;
        getUpdateNewDetail(params)
        setIsShowRedirect365(false)
        if(nameTitle === "Phish Clear") {
            LoginAzure({
                service: "auto_phish_clear",
                sourceId: id
            })
        } else {
            LoginAzure({
                service: "auto_block_list",
                sourceId: id
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            rule_name: '',
            description: '',
            is_active: false,
            condition_type: '',
            condition_value: '',
            status: '',
            priority: '',
            category: '',
            is_send_mail: false,
            is_response_mail: false,
            block_list: false,
            phish_clear: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            if (formik.values.is_send_mail && sendMail[ 'to' ] && sendMail[ 'to' ].length === 0) {
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
                return;
            }
            setOpenDrawer(false);
            setFlagHelperTextDescription(true);
            setFlagHelperTextRuleName(true);
            const params = {
                ...values,
                sendMailToOthers: {...sendMail, from_name: sendMail[ 'fromName' ].trim()},
                responseToReporter: {...responseMail, from_name: responseMail[ 'fromName' ].trim()}
            }
            setValueUpdate(params)
            handleUpdate(params)
        },
    });

    const handleOpen = () => {
        setParamSendMail(sendMail);
        setOpenDialog(true);
    }

    const handleOpenResponseMail = () => {
        setParamResponseMail(responseMail);
        setOpenDialogResponseMail(true);
    }
    const handleClose = () => {
        setOpenDialog(false);
        setValidateFromName(false);
        setValidateEmailContent(false);
        setValidateUserName(false);
        setValidateBaseSender(false);
    };
    const handleCloseResponseMail = () => {
        setOpenDialogResponseMail(false);
        setValidateFromName(false);
        setValidateEmailContent(false);
        setValidateUserName(false);
        setValidateBaseSender(false);
    };

    //show alert message validate To field
    const validatePopupSendMail = () => {
        if(!paramSendMail['fromName'].trim() ||
            paramSendMail[ 'to' ].length === 0||
            !paramSendMail[ 'email_content' ] ||
            !paramSendMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "") ||
            !paramSendMail['userName'].trim() ||
            !paramSendMail['baseSender']?.mail_sender_id) {
            if(!paramSendMail['fromName'].trim()) {
                setValidateFromName(true);
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            }
            // if(!paramSendMail['userName'].trim()) {
            //     setValidateUserName(true);
            //     setModalID(modalID + 1);
            //     setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            // }
            if(!paramSendMail['baseSender']?.mail_sender_id) {
                setValidateBaseSender(true);
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            }
            if(paramSendMail[ 'to' ].length === 0) {
            setValidateToField(true);
            setModalID(modalID + 1);
            setToastValue({
                type: 'error',
                message: 'Please complete the required fields.',
                isOpen: true,
                toastId: modalID
            });
            }
            if (!paramSendMail[ 'email_content' ] || !paramSendMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "")){
            setValidateEmailContent(true);
            setModalID(modalID + 1)
            setToastValue({
                type: 'error',
                message: 'Please complete the required fields.',
                isOpen: true,
                toastId: modalID
            });
            }
        } else {
            setSendMail(paramSendMail);
            handleClose();
            setOpenDrawer(true);
            setShowLeaveDialog(true);
            setValidateFromName(false);
            setValidateToField(false);
            setValidateEmailContent(false);
            setValidateUserName(false);
            setValidateBaseSender(false);
        }
    }

    const validatePopupResponseMail = () => {
        if(!paramResponseMail['fromName'].trim() ||
            !paramResponseMail[ 'email_content' ] ||
            !paramResponseMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "") ||
            !paramResponseMail['userName'].trim()||
            !paramResponseMail['baseSender']?.mail_sender_id) {
            if(!paramResponseMail['fromName'].trim()){
                setValidateFromName(true);
                setModalID(modalID + 1)
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            }
            // if(!paramResponseMail['userName'].trim()){
            //     setValidateUserName(true);
            //     setModalID(modalID + 1)
            //     setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            // }
            if(!paramResponseMail['baseSender']?.mail_sender_id){
                setValidateBaseSender(true);
                setModalID(modalID + 1)
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            }
            if (!paramResponseMail[ 'email_content' ] || !paramResponseMail[ 'email_content' ]?.replace(/<\/?[^>]+(>|$)/g, "")) {
                setValidateEmailContent(true)
                setModalID(modalID + 1)
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID})
            }
        } else {
            setResponseMail(paramResponseMail);
            handleCloseResponseMail()
            setOpenDrawer(true);
            setShowLeaveDialog(true);
            setValidateFromName(false);
            setValidateEmailContent(false);
            setValidateUserName(false);
            setValidateBaseSender(false);
        }
    }
    const onOpenDrawer = () => {
        setOpenDrawer(true);
        setShowLeaveDialog(true);
    }

    const handleDiscard = () => {
        initForm(ruleDetail);
        setOpenDrawer(false);
        setShowLeaveDialog(false);
    }
    const drawerButtons = () => (
        <Box
            sx={{height: '72px'}}
            role="presentation"
            component="span"
            display="flex"
            justifyContent="center"
            alignItems="center"

        >
            <Stack spacing={2} direction="row">
                <Button className={'btn-Discard'} data-testid={'btn-Discard'} variant="outlined" style={{color: '#345C8B', fontFamily: 'Metropolis'}}
                        onClick={() => handleDiscard()}>Discard</Button>
                <Button className={'btn-save-changes'} role={'button'} data-testid={'btn-save-changes'} variant="contained" style={{backgroundColor: '#345C8B', fontFamily: 'Metropolis'}}
                        type={"submit"}>Save Changes</Button>
            </Stack>
        </Box>
    );

    const initForm = (data: any) => {
        formik.setValues(
            {...data}
        );
        setSendMail({
            subject: data?.sendMailToOthers?.subject || '',
            from: data?.sendMailToOthers?.from || '',
            fromName: data?.sendMailToOthers?.from_name || 'No-Reply',
            recipients: data?.sendMailToOthers?.recipients || '',
            reportedBy: data?.sendMailToOthers?.reportedBy || '',
            to: data?.sendMailToOthers?.to || [],
            cc: data?.sendMailToOthers?.cc || [],
            email_content: data?.sendMailToOthers?.email_content || '',
            userName: data?.sendMailToOthers?.userName || 'phriendlyphishing',
            baseSender: data?.sendMailToOthers?.baseSender || '',
        });
        setResponseMail({
            subject: data?.responseToReporter?.subject || '',
            from: data?.responseToReporter?.from || '',
            fromName: data?.responseToReporter?.from_name || 'No-Reply',
            recipients: data?.responseToReporter?.recipients || '',
            reportedBy: data?.responseToReporter?.reportedBy || '',
            email_content: data?.responseToReporter?.email_content,
            userName: data?.responseToReporter?.userName || 'phriendlyphishing',
            baseSender: data?.responseToReporter?.baseSender || '',
        })
    }

    const getRuleDetailData = () => {
        if (id) {
            getRuleDetail({ruleId: id}).then(res => {
                if (res && res.data) {
                    setRuleDetail(res.data);
                    initForm(res.data);
                    getDefaultContent(res?.data?.condition_value);
                    setValueCheckedName(res?.data?.rule_name)
                }
                setLoading(false)
            }).catch((e)=>console.log(e));
        }
        getUpdatePhishClearBlockList()
    }

    useEffect(() => {
        setModalID(modalID + 1);
        setLoading(true)
        getRuleDetailData();
        if(sessionStorage.getItem('isShow365Stepper') === 'true' && locationState && locationState.status){
            setIsShowRedirect365Stepper(true)
            if(sessionStorage.getItem('step365stepper')=== '1'){
                setActiveStep(1)
            }
        } else {
            cancel365Stepper()
        }
        getUpdatePhishClearBlockList()
    }, []);

    useEffect(() => {
        if (locationState) {
            navigate('.', { replace: true, state: null });
        }
    }, [locationState, navigate]);

    // show message when form invalid
    const onValidation = () => {
        if (!formik.isValid) {
            setModalID(modalID + 1);
            setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
        }
    }

    // get default content for send mail reporter
    const getDefaultContent = (conditionValue: string) => {
        switch(conditionValue) {
            case 'Clean':
                setDefaultContentReporter(
                    "<p>Hi {{username}}<br><br></p>" +
                    "<p>Thank you for being vigilant and reporting the email for further analysis.<br><br></p>" +
                    "<p>This initial scan on the email was clean and no malicious content was found. The IT team will investigate the email further for other Phishing tactics and will take the appropriate action.<br><br></p>" +
                    "<p>Please let us know if you have any questions.<br><br></p>" +
                    "<p>Kind Regards,</p>" +
                    "<p>Security Team</p>"
                    );
                break;
            case 'Infected':
                setDefaultContentReporter(
                    "<p>Hi {{username}}<br><br></p>" +
                    "<p>Thank you for being vigilant and reporting the email for further analysis.<br><br></p>" +
                    "<p>This email initial scan has detected malicious content and is being investigated further by the security team.</p>" +
                    "<p>If you or anyone else on the team has clicked the email, please let us know as soon as possible so we can take action.<br><br></p>" +
                    "<p>Thank you for reporting this to keep our organisation safe!<br><br><p>" +
                    "<p>Kind Regards,</p>" +
                    "<p>Security Team</p>"
                );
                break;
            case 'Error':
            case 'Unscannable':
                setDefaultContentReporter(
                    "<p>Hi {{username}}<br><br></p>" +
                    "<p>Thank you for being vigilant and reporting the email for further analysis.<br><br></p>" +
                    "<p>We are not able to determine the intent of this email from the initial scan so it will require further investigation by the security team.<p>" +
                    "<p>If you or anyone else on the team has clicked the email, please let us know as soon as possible so we can take action.<br><br></p>" +
                    "<p>Thank you for reporting this to keep our organisation safe!<br><br><p>" +
                    "<p>Kind Regards,</p>" +
                    "<p>Security Team</p>"
                );
                break;
            default:
                setDefaultContentReporter('');
                break;
        }
    }

    const helperTextRuleName = React.useMemo(() => {
        if (formik.values.rule_name && formik.values.rule_name.length > 34) {
            return 'Maximum 35 characters, cannot input more';
        }
        return '';
    }, [formik.values.rule_name, flagHelperTextRuleName]);

    const helperTextDescription = React.useMemo(() => {
        if (formik.values.description && formik.values.description.length > 254) {
            return 'Maximum 255 characters, cannot input more';
        }
        return '';
    }, [formik.values.description]);

    //remove text for input with multiple line
    const handleChangeMultiline = (e: any) => {
        onOpenDrawer();
        if (e?.target?.value.toString().length > 255) {
            formik.setFieldValue('description',e.target.value.slice(256, e?.target?.value.toString().length - 1));
        }
    };
    const handleRuleName = (e: any) => {
        setValueCheckedName(e.target.value)
        onOpenDrawer()
    };

    return (
        <>
            <form onSubmit={(value) => {
                formik.handleSubmit(value);
                onValidation()
            }}>
                <div className='inbox-page page-content-wrapper'>
                    <PageHeader setInboxEvent={false}
                                pageTitle={ruleDetail ? `Rule Details - ${ruleDetail[ 'rule_name' ]}` : <Skeleton animation="wave" sx={{width: '250px', height: '44px'}}/>}
                                breadCrumbData={automationRuleDetailLinks}/>
                    <Box className='rule-box'
                         sx={{
                             display: 'grid',
                             gridTemplateColumns: {sm: '1fr'},
                         }}>
                        {!loading ? <label className={'box-title'}>
                            Rule Information
                        </label> : <Skeleton animation="wave" sx={{width: '300px', height: '60px'}}/>}
                        {!loading ? <InputLabel className={'input-label'}>
                            Rule Name <span style={{color: 'red'}}>*</span>
                        </InputLabel> : <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/>}
                        {!loading ? <OutlinedInput
                          name={'rule_name'}
                          id={'rule_name'}
                          data-testid={'inputRuleName'}
                          className={'input-field'}
                          classes={{
                              root: 'input-rule-name'
                          }}
                          // onFocus={() => onOpenDrawer()}
                          placeholder={'Enter automation rule name here'}
                          size={"small"}
                          value={formik.values.rule_name}
                          onChange={(e) => {
                              formik.handleChange(e);
                              handleRuleName(e)
                          }}
                          error={formik.touched.rule_name && Boolean(formik.errors.rule_name)}
                          onBlur={formik.handleBlur}
                          inputProps={{
                              maxLength: 35,
                          }}
                          onFocus={() => {
                              setFlagHelperTextRuleName(false)
                          }}
                        /> : <Skeleton animation="wave" sx={{width: '400px', height: '50px'}}/>}
                        <FormHelperText error id="rule_name"
                                        style={{margin: '0 0 24px 16px', fontWeight: 500, fontSize: '12px'}}>
                            { Boolean(formik.errors.rule_name) ? (formik.touched.rule_name && formik.errors.rule_name) : (!flagHelperTextRuleName && helperTextRuleName)}
                        </FormHelperText>
                        {!loading ? <InputLabel className={'input-label'}>
                            Description <span style={{color: 'red'}}>*</span>
                        </InputLabel> : <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/>}
                        {!loading ? <OutlinedInput
                          name={'description'}
                          id={'description'}
                          className={'input-field'}
                          style={{height: '96px'}}
                          multiline={true}
                          rows={4}
                          placeholder={'Describe the purpose of this rule...'}
                          value={formik.values.description}
                          onChange={(e) => {
                              formik.handleChange(e);
                              handleChangeMultiline(e)
                          }}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          onBlur={formik.handleBlur}
                          inputProps={{
                              maxLength: 255,
                          }}
                          onFocus={() => {
                              setFlagHelperTextDescription(false)
                          }}
                        /> : <Skeleton animation="wave" sx={{width: '400px', height: '100px'}}/>}
                        <FormHelperText error id="description"
                                        style={{margin: '0 0 24px 16px', fontWeight: 500, fontSize: '12px'}}>
                            {Boolean(formik.errors.description) ? (formik.touched.description && formik.errors.description) : (!flagHelperTextDescription && helperTextDescription)}
                        </FormHelperText>
                        {!loading ? <InputLabel className={'input-label'}>
                            Status
                        </InputLabel> : <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/>}
                        {!loading ? <FormControlLabel
                          control={
                              <Switch
                                data-testid={'ckIsActive'}
                                name={'is_active'}
                                id={'is_active'}
                                checked={formik.values.is_active}
                                onChange={(e) => {
                                    onOpenDrawer();
                                    formik.handleChange(e);
                                }}
                                color="success"/>
                          }
                          style={{width: "fit-content"}}
                          label={formik.values.is_active ? "Active" : "Inactive"}
                          className={formik.values.is_active ? 'switch-label-active' : 'switch-label-inactive'}
                        /> : <Skeleton animation="wave" sx={{width: '200px', height: '30px'}}/>}
                    </Box>
                    <Box className={openDrawer ? 'rule-box mg-bottom' : 'rule-box'}
                         sx={{
                             display: 'grid',
                             marginBottom: '48px'
                         }}
                    >
                        {!loading ? <label className={'box-title'}>Rule details</label> : <Skeleton animation="wave" sx={{width: '300px', height: '60px'}}/>}
                        {!loading ? <div className={'number-title'}>
                            <div className={'number-circle'}>1</div>
                            Choose how this rule should be triggered
                        </div> : <Skeleton animation="wave" sx={{width: '400px', height: '40px'}}/>}
                        <div className={'sub-box-swapper'}>
                            {!loading ? <InputLabel className={'input-label'}>
                                Condition Type <span style={{color: 'red'}}>*</span>
                            </InputLabel> : <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/>}
                            {!loading ? <Select
                              name={'condition_type'}
                              displayEmpty
                              value={formik.values.condition_type}
                              onChange={formik.handleChange}
                                //map Scan Result text to Analysis Result text for Threat Intelligence MVP
                              renderValue={(selected: any) => {
                                  if (!selected) {
                                      return <>Select a condition type</>;
                                  }
                                  return selected.toLowerCase() === "scan result" ? "Analysis Result" : selected;
                              }}
                              className={'input-select-field'}
                              disabled={true}
                              IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                              size={'small'}
                            >
                                <MenuItem
                                  key={1}
                                  value={'Scan Result'}
                                  style={{paddingRight: '12px'}}
                                >
                                    {'Scan Results'} <CheckIcon className={'checked-icon'}/>
                                </MenuItem>
                            </Select> : <Skeleton animation="wave" sx={{width: '400px', height: '60px'}}/>}
                            {!loading ? <InputLabel className={'input-label'}>
                                Condition Value <span style={{color: 'red'}}>*</span>
                            </InputLabel> : <Skeleton animation="wave" sx={{width: '200px', height: '20px'}}/>}
                            {!loading ? <Select
                              displayEmpty
                              name={'condition_value'}
                              value={formik.values.condition_value}
                              onChange={formik.handleChange}
                                //map Infected text to Threat text for Threat Intelligence MVP
                              renderValue={(selected) => {
                                  if (!selected) {
                                      return <>Select a condition value</>;
                                  }
                                  return selected.toLowerCase() === "infected" ? "Threat" : selected;
                              }}
                              className={'input-select-field'}
                              disabled
                              IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                              size={"small"}
                            >
                                {conditionValues.map((value, index) => (
                                  <MenuItem
                                    key={index}
                                    value={value}
                                    style={{paddingRight: '12px'}}
                                  >
                                      {value} <CheckIcon className={'checked-icon'}/>
                                  </MenuItem>
                                ))}
                            </Select> : <Skeleton animation="wave" sx={{width: '400px', height: '60px'}}/>}
                        </div>
                        {!loading ? <div className={'number-title'}>
                            <div className={'number-circle'}>2</div>
                            Choose the action to be taken on matched messages
                        </div> : <Skeleton animation="wave" sx={{width: '400px', height: '40px'}}/>}
                        <div className={'sub-box-swapper box-action'}>
                            <Box className={"title"}>Message Details</Box>
                            <FormGroup row className={'group'}>
                                {!loading ? <InputLabel className={'input-label-select'}>
                                    Set status:
                                </InputLabel> : <Skeleton animation="wave" sx={{width: '100px', height: '20px'}}/>}
                                {!loading ? <Select
                                   inputProps={{'data-testid': 'statusSelect'}}
                                  displayEmpty
                                  name={'status'}
                                  value={formik.values.status}
                                  onChange={(e) => {
                                      formik.handleChange(e);
                                      onOpenDrawer()
                                  }}
                                  renderValue={(selected: any) => {
                                      if (!selected) {
                                          return <>Select a status</>;
                                      }
                                      return selected;
                                  }}
                                  className={'group-select-field'}
                                  IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                                  size={"small"}
                                >
                                    {status.map((status, index) => (
                                      <MenuItem
                                        key={index}
                                        value={status}
                                        style={{paddingRight: '12px'}}
                                      >
                                          {status} <CheckIcon className={'checked-icon'}/>
                                      </MenuItem>
                                    ))}

                                </Select> : <Skeleton animation="wave" sx={{width: '400px', height: '60px'}}/>}
                            </FormGroup>
                            <FormGroup row className={'group'}>
                                {!loading ? <InputLabel className={'input-label-select'}>
                                    Set priority:
                                </InputLabel> : <Skeleton animation="wave" sx={{width: '100px', height: '20px'}}/>}
                                {!loading ? <Select
                                  inputProps={{'data-testid': 'prioritySelect'}}
                                  displayEmpty
                                  name={'priority'}
                                  value={formik.values.priority}
                                  onChange={(e) => {
                                      formik.handleChange(e);
                                      onOpenDrawer()
                                  }}
                                  renderValue={(selected: any) => {
                                      if (!selected) {
                                          return <>Select a status</>;
                                      }
                                      return selected;
                                  }}

                                  className={'group-select-field'}
                                  IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                                  size={"small"}
                                >
                                    {priorities.map((priority, index) => (
                                      <MenuItem
                                        key={index}
                                        value={priority}
                                        style={{paddingRight: '12px'}}
                                      >
                                          {priority} <CheckIcon className={'checked-icon'}/>
                                      </MenuItem>
                                    ))}
                                </Select> : <Skeleton animation="wave" sx={{width: '400px', height: '60px'}}/>}
                            </FormGroup>
                            <FormGroup aria-label="position" row className={'group'}>
                                {!loading ? <InputLabel className={'input-label-select'}>
                                    Set category:
                                </InputLabel> : <Skeleton animation="wave" sx={{width: '100px', height: '20px'}}/>}
                                {!loading ? <Select
                                  displayEmpty
                                  inputProps={{'data-testid': 'categorySelect'}}
                                  name={'category'}
                                  value={formik.values.category}
                                  onChange={(e) => {
                                      formik.handleChange(e);
                                      onOpenDrawer()
                                  }}
                                  renderValue={(selected) => {
                                      if (!selected) {
                                          return <>Select a category</>;
                                      }
                                      return selected;
                                  }}
                                  className={'group-select-field'}
                                  IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                                  size={"small"}
                                >
                                    {categoríes.map((category, index) => (
                                      <MenuItem
                                        key={index}
                                        value={category}
                                        style={{paddingRight: '12px'}}
                                      >
                                          {category} <CheckIcon className={'checked-icon'}/>
                                      </MenuItem>
                                    ))}
                                </Select> : <Skeleton animation="wave" sx={{width: '400px', height: '60px'}}/>}
                            </FormGroup>
                        </div>
                        {/*Phish Clear*/}
                        {!loading ? <AutomationRuleActionCard
                            title={"Phish Clear"}
                            checkboxTitle={"Enable Phish Clear"}
                            tooltipText={t("AutomationRuleActionCard.phishclear_tooltip")}
                            childElement={autoPhishClearData?.authenticated ? <CredentialCard payload={{
                                                            service_key: 'phish_clear',
                                                            service_id: 'auto_phish_clear',
                                                            title: t("credentialCard.AR_detail_phish_clear_title"),
                                                            authenticated: autoPhishClearData.authenticated,
                                                            accountName: autoPhishClearData.account_name,
                                                            accountEmail: autoPhishClearData.account_email,
                                                            loginAt: autoPhishClearData.login_at,
                                                            lastUpdate: "16/7/2024",
                                                            disableBtn: true,
                                                        }}
                                            ></CredentialCard> : null}
                            checkboxName={'phish_clear'}
                            formik={formik}
                            onOpenDrawer={()=>onOpenDrawer()}
                            /> : <Skeleton animation="wave" sx={{width: '400px', height: '40px'}}/>}

                        {/*Block List*/}
                        {!loading ? <AutomationRuleActionCard
                            title={"Block List"}
                            checkboxTitle={"Add to Block List"}
                            tooltipText={t("AutomationRuleActionCard.blocklist_tooltip")}
                            checkboxName={'block_list'}
                            formik={formik}
                            onOpenDrawer={()=>onOpenDrawer()}
                            childElement={autoBlockListData?.authenticated ? <CredentialCard payload={{
                                service_key: 'block_list',
                                service_id: 'auto_block_list',
                                title: t("credentialCard.AR_detail_phish_clear_title"),
                                authenticated: autoBlockListData?.authenticated,
                                accountName: autoBlockListData.account_name,
                                accountEmail: autoBlockListData.account_email,
                                loginAt: autoBlockListData.login_at,
                                lastUpdate: "16/7/2024",
                                disableBtn: true,
                            }}
                            ></CredentialCard> : null}
                        /> : <Skeleton animation="wave" sx={{width: '400px', height: '40px'}}/>}

                        {!loading ? <div className={'number-title'}>
                            <div className={'number-circle'}>3</div>
                            Choose how you would like to report this rule
                        </div> : <Skeleton animation="wave" sx={{width: '400px', height: '40px'}}/>}

                        {/*box response mail*/}
                        <div id={'response-mail-box'}
                             className={formik.values.is_response_mail ? 'sub-box-swapper box-send-mail checked-box' : 'sub-box-swapper box-send-mail'}>
                            <FormGroup>
                                {!loading ? <Grid container>
                                    <Grid item={true} xs={7}>
                                        <FormControlLabel
                                            value="top"
                                            control={<Checkbox
                                                name={'is_response_mail'}
                                                data-testid={'is_response_mail'}
                                                checked={formik.values.is_response_mail}
                                                onChange={formik.handleChange}
                                                onFocus={() => onOpenDrawer()}
                                            />}
                                            label="Response to user"
                                            style={{height: '17px'}}
                                        />
                                        <p className={'box-send-mail-description'}>Automatic response to the user when they report an email.</p>
                                    </Grid>
                                    <Grid item={true} xs={5}>

                                        <Stack direction="row" alignItems="center" spacing={'11px'}>
                                            <Button
                                                data-testid={'btn-response-email'}
                                                className={'MailOutlineIcon'}
                                                onClick={handleOpenResponseMail}
                                                variant="text" size={"large"}
                                                style={{
                                                    width: '160px',
                                                    maxWidth: '160px',
                                                    backgroundColor: '#D8E7F5',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    marginLeft: 'auto',
                                                    color: '#345C8B',
                                                }}>
                                                <MailOutlineIcon
                                                  style={{paddingRight: '8px', fontSize: '17px'}}/>
                                                <span style={{
                                                    borderLeft: '1px solid #9AACC3',
                                                    paddingLeft: '6px'
                                                }}> Email Content</span>
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid> : <Skeleton animation="wave" sx={{width: '600px', height: '100px'}}/>}
                            </FormGroup>
                        </div>

                        {/*box send mail*/}
                        <div id={'send-mail-box'} style={{marginBottom: '0px'}}
                             className={formik.values.is_send_mail ? 'sub-box-swapper box-send-mail checked-box' : 'sub-box-swapper box-send-mail'}>
                            <FormGroup>
                                {!loading ? <Grid container>
                                    <Grid item={true} xs={7}>
                                        <FormControlLabel
                                          value="top"
                                          control={<Checkbox
                                            name={'is_send_mail'}
                                            data-testid={'is_send_mail'}
                                            checked={formik.values.is_send_mail}
                                            onChange={formik.handleChange}
                                            onFocus={() => onOpenDrawer()}
                                          />}
                                          label="Forward reported email"
                                          style={{height: '17px'}}
                                        />
                                        <p className={'box-send-mail-description'}>
                                            The reported email will be sent as an attachment to other recipients.
                                        </p>
                                    </Grid>
                                    <Grid item={true} xs={5}>
                                        <Stack direction="row" alignItems="center" spacing={'11px'}>
                                            <Button
                                                data-testid={'btn-forward-reported-email'}
                                                onClick={handleOpen}
                                                variant="text"
                                                size={"large"}
                                                style={{
                                                    maxWidth: '160px',
                                                    width: '160px',
                                                    backgroundColor: '#D8E7F5',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    marginLeft: 'auto',
                                                    color: '#345C8B',
                                                }}>
                                                <MailOutlineIcon
                                                    style={{paddingRight: '8px', fontSize:'17px'}}/>
                                                <span style={{
                                                    borderLeft: '1px solid #9AACC3',
                                                    paddingLeft: '6px'
                                                }}> Email Content</span>
                                            </Button>
                                        </Stack>
                                        {(formik.values.is_send_mail && sendMail[ 'to' ] && !(sendMail[ 'to' ].length > 0)) && <FormHelperText error id="description"
                                                                                       style={{
                                                                                           marginLeft: 'auto',
                                                                                           marginTop: '8px',
                                                                                           width: 'fit-content',
                                                                                           fontWeight: 500,
                                                                                           fontSize: '12px',
                                                                                           color: '#C80000',
                                                                                       }}>
                                            <ErrorIcon sx={{fontSize: '16px', verticalAlign: 'middle', color: '#C80000',}}/><span> Please add To field in the Email Content.</span>
                                        </FormHelperText>}
                                    </Grid>
                                </Grid> : <Skeleton animation="wave" sx={{width: '600px', height: '100px'}}/>}
                            </FormGroup>
                        </div>
                    </Box>

                    <Drawer
                        PaperProps={{
                            sx: {
                                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.15)',
                                zIndex: 100
                            }
                        }}
                        anchor={'bottom'}
                        open={openDrawer}
                        hideBackdrop={false}
                        variant={'persistent'}
                    >
                        {drawerButtons()}
                    </Drawer>
                </div>
            </form>
            <Modal idModal='modal-send-mail'
                   disableEnforceFocus={true}
                   fullScreen={isMobileOnly ? true : false}
                   openDialog={openDialog}
                   title={'Forward reported email'}
                   actionAgree={validatePopupSendMail}
                   actionCancel={handleClose}
                   textAgree={'Done'}
                   textCancel={'Cancel'}
                   onClose={handleClose}
                   cssClass={'btn-right'}
            >
                <SendMailModal defaultParams={defaultParams}
                               paramSendMail={paramSendMail}
                               setParamSendMail={setParamSendMail}
                               scanResult={{'scan-result': null}}
                               isFromName={true}
                               attachedLabel={'The original email will be attached as an attachment and sent to recipients:'}
                               checkboxInputCC={true}
                               subjectPrefix={`- {{DateReported}} - {{ReportedEmailSubject}}`}
                               validateFromName={validateFromName}
                               setValidateFromName={setValidateFromName}
                               defaultContent={defaultContent}
                               fromNameError={'This field is required.'}
                               editorError={'This field is required.'}
                               validateToField={validateToField}
                               setValidateToField={setValidateToField}
                               validateEmailContent={validateEmailContent}
                               setValidateEmailContent={setValidateEmailContent}
                               validateUserName={validateUserName}
                               setValidateUserName={setValidateUserName}
                               validateBaseSender={validateBaseSender}
                               setValidateBaseSender={setValidateBaseSender}
                               lineBelowContent={false}
                               isMessageDownload={true}
                />
            </Modal>
            <Modal idModal='modal-send-mail'
                   disableEnforceFocus={true}
                   fullScreen={isMobileOnly ? true : false}
                   openDialog={openDialogResponseMail}
                   title={'Response to user'}
                   actionAgree={validatePopupResponseMail}
                   actionCancel={handleCloseResponseMail}
                   textAgree={'Done'}
                   textCancel={'Cancel'}
                   onClose={handleCloseResponseMail}
                   cssClass={'btn-right'}
            >
                <SendMailModal defaultParams={defaultParams} paramSendMail={paramResponseMail}
                               setParamSendMail={setParamResponseMail} scanResult={{'scan-result': null}}
                               isFromName={true}
                               defaultContent={defaultContentReporter}
                               isHiddenToCC={true}
                               subjectPrefix={`- {{DateReported}} - {{ReportedEmailSubject}}`}
                               validateFromName={validateFromName}
                               setValidateFromName={setValidateFromName}
                               fromNameError={'This field is required.'}
                               editorError={'This field is required.'}
                               validateEmailContent={validateEmailContent}
                               setValidateEmailContent={setValidateEmailContent}
                               validateUserName={validateUserName}
                               setValidateUserName={setValidateUserName}
                               validateBaseSender={validateBaseSender}
                               setValidateBaseSender={setValidateBaseSender}
                />
            </Modal>
            <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message}
                   toastId={toastValue.toastId} autoHideDuration={2000}/>
            <LoadingPage open={loadingPage}/>
            <LeavePageDialog
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <RedirectTo365 show={isShowRedirect365} nameTitle={nameTitle} onCancel={()=>cancel365()} onSubmit={()=> handleOnSubmit365()}/>
            <RedirectTo365WithStepper
              show={isShowRedirect365Stepper}
              onCancel={()=> cancel365Stepper()}
              onSubmit={(step:number)=>{handleOnSubmit365Stepper(step)}}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
        </>
    );
}
