import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {StepperProps} from "./Stepper.props";
import {StyledMenuProps} from "../StyledMenu";
import {useEffect} from "react";
import './Stepper.styles.css'
const stepsLabel = [
  {
    id:1,
    label: <><b>Phish Clear</b> requires <br/>Microsoft 365 credentials.</>
  },{
    id:2,
    label: <span style={{color:'#000000'}}><b>Block List</b> requires <br/>Microsoft 365 credentials.</span>,
  },
];

export const VerticalLinearStepper:React.FC<StepperProps>  = ({submit,activeStep,setActiveStep}) => {
  const [steps, setSteps] = React.useState(stepsLabel);
  const nextStepsLabel0 = [
    {
      id:1,
      label: <><b>Logged in for Phish Clear</b></>
    },{
      id:2,
      label: <span style={{color:'#000000', opacity:0.9}}><b>Block List</b> requires <br/>Microsoft 365 credentials.</span>,
    },
  ];
  const nextStepsLabel1 = [
    {
      id:1,
      label: <><b>Logged in for Phish Clear</b></>
    },{
      id:2,
      label: <><b>Logged in for Block List</b> </>,
    },
  ];
  const handleNext = () => {
    if(activeStep === 0){
      setSteps(nextStepsLabel0)
    }
    if(activeStep === 1){
      setSteps(nextStepsLabel1)
    }
    submit(activeStep)
    setActiveStep((prevActiveStep:number) => prevActiveStep + 1);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <Box className={'vertical-linear-stepper-wrapper'} sx={{ maxWidth: '100%', backgroundColor:'#DFF4ED', padding:'20px 40px', borderRadius: '10px'}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.id} sx={{display:'flex'}}>
            <StepLabel
              sx={{display:'flex', color:'red'}}
            >
              {step.label}
            </StepLabel>
            <StepContent sx={{borderLeft:'none', margin:'auto 0 auto 12px', paddingRight:'0px'}}>
             <Box>
                <div>
                  <Button
                    data-testid={'btn-stepper'}
                    variant="contained"
                    onClick={handleNext}
                    style={{backgroundColor: '#345C8B', fontSize:'14px'}}
                  >
                    {activeStep === 1 ? 'Login for Block List':'Save changes and Login'}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}