import React, {useState} from 'react';
import {ModalProps} from './Modal.props';
import './Modal.styles.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "../../atoms/Button";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {Checkbox, FormControlLabel} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

export const Modal: React.FC<ModalProps> = ({
    cssClass = 'default-dialog',
    variant,
    idModal,
    openDialog,
    title,
    content,
    actionAgree,
    actionCancel,
    /* istanbul ignore next */
    textAgree= 'Agree',
    /* istanbul ignore next */
    textCancel= 'Cancel',
    children,
    onClose,
    /* istanbul ignore next */
    fullScreen= false,
    handleOnChange,
    disableEnforceFocus
}) => {
    let icon:any
    if (variant === 'delete'){
        icon = <ErrorIcon style={{marginRight: '10px'}}/>
    } else if(variant === 'success'){
        icon = <CheckCircleIcon style={{marginRight: '10px'}}/>
    } else if(variant === 'warning'){
        icon = <WarningIcon style={{marginRight: '10px'}} htmlColor={'#F29100'}/>
    }

    return (
      <Dialog
        disableEnforceFocus={disableEnforceFocus}
        fullScreen={true}
        open={openDialog}
        onClose={actionCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id={idModal}
        data-testid={idModal}
        className={cssClass}
        onClick={(e) => e.stopPropagation()}
        disableEscapeKeyDown
      >
          <DialogTitle id="alert-dialog-title">
              {icon}
              {title}
              {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 8,
                      top: 12,
                      color: '#666666',
                  }}
                >
                    <CloseIcon />
                </IconButton>
              ) : null}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {content}
              </DialogContentText>
              {children}
          </DialogContent>
          <DialogActions id='alert-dialog-action' sx={{padding: '24px'}}>
              {variant === 'send-mail' ? <FormControlLabel control={<Checkbox id={'to-resolved'} onChange={handleOnChange} defaultChecked/>} label="Change status to Resolved"/> : null}
              <div className={'action-btn'} style={{display: "flex"}}>
                  {actionCancel ? <Button title={textCancel}
                                          titleMargin={'0'}
                                          dataTestId="modal-cancel-btn"
                                          variant={'outline'}
                                          cssClass={`pure-material-button-like shadow-sm`}
                                          backgroundColor={'transparent'}
                                          width={'fit-content'} height={'40px'}
                                          fontSize={14}
                                          fontWeight={'600'}
                                          color={'#345C8B'}
                                          border={'1px solid #E0E0E0'}
                                          borderRadius={'4px'}
                                          padding={"8px 24px"}
                                          onClick={actionCancel}/> : /* istanbul ignore next */null}

                  {actionAgree ? <Button title={textAgree}
                                         titleMargin={'0'}
                                         dataTestId="modal-agree-btn"
                                         cssClass={`pure-material-button-like agree ${variant}`}
                                         variant={'outline'}
                                         width={'fit-content'} height={'40px'}
                                         fontSize={14}
                                         fontWeight={'600'}
                                         color={'#fff'}
                                         borderRadius={'4px'}
                                         padding={"8px 24px"}
                                         margin={'0 0 0 8px'}
                                         onClick={actionAgree}/> : /* istanbul ignore next */null}

              </div>
          </DialogActions>
      </Dialog>
    );
};
