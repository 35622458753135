import React, {useRef, useEffect, Fragment} from 'react';

import { AnalysisRiskBoxProps } from './AnalysisRiskBox.props';
import './AnalysisRiskBox.styles.css'
import Box from "@mui/material/Box";
import {AnalysisBoxItem} from "../AnalysisBoxItem";
import Grid from "@mui/material/Grid";
import {Chip, Typography} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import ErrorIcon from '@mui/icons-material/Error';
import './AnalysisRiskBox.styles.css';
import {RadialGraph} from "../../atoms/RadialGraph";

export const AnalysisRiskBox: React.FC<AnalysisRiskBoxProps>= ({
    payload = {}
}) => {
  const theme = useTheme();

  return <Box sx={{py: 2, px: 3}} className={'analysis-risk-box analysis-risk-box-' + payload?.priority}>
      {
           <Fragment>
              <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'relative'}}>
                  <Box>
                  {
                      payload?.priority =='high' ? <>
                          <Chip icon={<ErrorIcon sx={{ms: 0, mr:0}}/>} color="error" label={payload.content ? "Issue detected" : "Threat detected"} sx={{
                              borderRadius: "4px",
                              fontWeight: "600",
                              padding: "4px 12px",
                              marginLeft: "0px !important",
                              marginBottom: "8px",
                              color: "#fff"
                          }} />
                      </> : payload?.priority =='medium' || payload?.priority =='moderate' ? <>
                          <Chip icon={<ErrorIcon sx={{ms: 0, mr:0}}/>} color="warning" label="Undetermined" sx={{
                              borderRadius: "4px",
                              fontWeight: "600",
                              padding: "4px 12px",
                              marginLeft: "0px !important",
                              marginBottom: "8px",
                              color: "#fff"
                          }} />
                      </> : null
                  }
                  <Typography variant={'body2'} className={"app-label"} sx={{marginBottom: "8px"}} >{payload.label}</Typography>
                  </Box>
                  {
                      payload?.score >= 0 ?
                        <Box sx={{minWidth: '40px', marginLeft: "10px", width: '40px', height: '40px'}}>
                            <RadialGraph value={payload?.score} chartLayoutPosition={{left: 20}} isToolTipHover={true} chartStyle={{stroke: 'unset'}}/>
                        </Box> : <></>
                  }
              </Box>
               {
                   payload.content ? <Fragment>
                    { payload.content }
                    </Fragment>  : <Fragment>
                        <Typography variant={'body2'}  sx={{fontWeight: 600, wordBreak: 'break-word', whiteSpace: 'break-spaces', textOverflow: 'ellipsis'}} >{payload.value}</Typography>
                    </Fragment>
               }

          </Fragment>
      }
    </Box>
};
