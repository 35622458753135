import React, {useEffect, useState} from 'react';
import {AutomationRuleActionCardProps} from './AutomationRuleActionCard.props';
import "./AutomationRuleActionCard.styles.css";
import {Box, IconButton, Tooltip, FormGroup, FormControlLabel, styled, Checkbox } from '@mui/material';
import HelpIcon from "@mui/icons-material/Help";
import {useTranslation} from "react-i18next";

const CheckboxStyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
    "& .MuiCheckbox-root.Mui-checked": {
        color: "#345C8B"
    },
    "& .MuiSvgIcon-root": {
        fontSize: 22
    },
    "& .MuiFormControlLabel-label": {
        color: "rgba(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
    }
}));

export const AutomationRuleActionCard: React.FC<AutomationRuleActionCardProps> = ({
    title = "",
    childElement = null,
    isUsedCheckBox = true,
    checkboxTitle = "",
    tooltipText = "",
    formik,
    checkboxName='',
    onOpenDrawer
}) => {
    const {t} = useTranslation();
    return (
        <Box className={"automation-rule-action-card-wrapper"} sx={{backgroundColor: formik?.values[checkboxName] ? '#F9F9F9': '#FFFFFF'}}>
         <Box className={"title-side"}>
             <span className={"title"}>{title}</span>
             <Tooltip
                 placement={"top"}
                 title={tooltipText}
                 componentsProps={{
                     tooltip: {
                         sx: {
                             width: "auto",
                             maxWidth: "700px",
                             padding: "8px",
                             position: "relative",
                             top: "10px",
                             left: "250px"
                         },
                     },
                 }}
             >
                 <IconButton className='info-action'><HelpIcon fontSize={'small'} htmlColor={"#193051"}/></IconButton>
             </Tooltip>

             {
                 isUsedCheckBox ? <FormGroup sx={{marginLeft: "24px", fontSize: "14px"}}>
                     <CheckboxStyledFormLabel
                       control={<Checkbox
                         data-testid={'check-box-action-card'}
                         className={'check-box-action-card'}
                         name={checkboxName}
                         checked={formik?.values[checkboxName]}
                         onChange={ formik?.handleChange}
                         onFocus={onOpenDrawer}
                        />}
                       label={checkboxTitle ? checkboxTitle : ""} />
                 </FormGroup> : <></>
             }
         </Box>
         <Box className={"content-side"}>
             {childElement || <div dangerouslySetInnerHTML={{__html: t("AutomationRuleActionCard.ms365_credentials_required")}}></div>}
         </Box>
        </Box>
    );
}
