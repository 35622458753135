import React, {useState, useContext, useEffect, Fragment, useRef} from 'react'
import { InboxDetailsActionProps } from './InboxDetailsAction.props'
import './InboxDetailsAction.styles.css'
import {Box, Stack, Typography} from "@mui/material";
import Divider from '@mui/material/Divider';
import {GlobalContext} from '../../../context/GlobalState';
import DropDownSelect from "../../molecules/DropDownSelect/DropDownSelect";
import {
    ARCHIVE,
    CONSTANTS,
    SEND_EMAIL,
    DELETE,
    PURGE_OPTION,
    CONVERT_TO_EMAIL_TEMPLATE,
    ADD_TO_BLOCK_LIST,
    ADMIN_ROLE,
    SETTING_TYPE_REPORTED_EMAILS_ACTIONS,
    EXPORT_ERROR_MESSAGES, SETTING_BLOCK_LIST, BLOCK_LIST_API_ACTIONS
} from '../../../constants/contants';
import {
    sendMessageEmail,
    updateInboxMessage,
    updateTriageInboxes,
    importToService,
    getUsedEmail, acquireBlockListEntries, getBlockListsSetting, syncBlockListEntries
} from "../../../api/emailTriage";
import {Modal} from "../../atoms/Modal";
import {SendMailModal} from "../SendMailModal";
import {DropDownList} from "../../atoms/DropDownList";
import {DropdownContent} from "../../molecules/DropDownContent";
import {Toast} from "../../atoms/Toast";
import {isMobileOnly} from "react-device-detect";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Global } from '@emotion/react';
import ConvertToTemplateModal, { IConvertToTemplateData } from '../ConvertToTemplateModal/ConvertToTemplateModal';
import Message from '../Message/Message';
import { Error, CheckCircle } from '@mui/icons-material';
import {LoadingPage} from "../LoadingPage";
import { useRoleContext } from '../../../context/RoleContext';
import {AddBlockListModal} from "../AddBlockListModal";

import {Ms365BlockListAuth} from "../../services/Ms365BlockListAuth";
import jwt_decode from "jwt-decode";

export const InboxDetailsAction:React.FC<InboxDetailsActionProps> = (props) => {
    let emailID:any = props.emailID;
    const detail:any = props.detail;
    const userLocalData:any = localStorage.getItem('userData');
    let userData:any = [];
    const blockListTokenKey = 'ms365_bl_tk_' + sessionStorage.getItem('currentZone');
    if(userLocalData) {
        userData = JSON.parse(userLocalData);
    }
    const defaultConvertValues = {
        subject: detail.subject,
        type: 'campaign',
        ranking: userData.role === ADMIN_ROLE ? 1 : 50,
    };
    let { actionRules, categories } = useContext(GlobalContext)['globalState'];
    const [openDialog, setOpenDialog] = useState(false);
    const [openConvertDialog, setOpenConvertDialog] = useState(false);
    const [openConvertCompleteDialog, setOpenConvertCompleteDialog] = useState(false);
    const [openConvertErrorDialog, setOpenConvertErrorDialog] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false)
    const [contentModal, setContentModal] = useState({title: '', content: '', action: ''});
    const [convertErrorModalContent, setConvertErrorModalContent] = useState("");
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [modalID, setModalID] = useState(1)
    const [changeStatus, setChangeStatus] = useState(props.status)
    // validate from name on send email modal
    const [validateFromName, setValidateFromName] = useState(false);
    // validate from name on send email modal
    const [validateUserName, setValidateUserName] = useState(false);
    // validate from name on send email modal
    const [validateBaseSender, setValidateBaseSender] = useState(false);
    const [validateToField, setValidateToField] = useState(false);
    const [validateEmailContent, setValidateEmailContent] = useState(false);
    // @ts-ignore
    const zoneID:any = sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone');
    const [convertTemplateData, setConvertTemplateData] = useState<IConvertToTemplateData>(defaultConvertValues);
    const { setting } = useRoleContext()
    const { globalState } = useContext(GlobalContext);
    const isMS365 = globalState.ms365

    const blockListAuthRef = useRef<any>();
    const addBlockListRef = useRef<any>();
    const [blocklistTenantId, setBlocklistTenantId] = useState<any>(null)
    const [onBlockListAccessGained, setOnBlockListAccessGained] = useState<any>(null)

    const isAuthenticated = sessionStorage.getItem("isAuthenticated")

    const dataParams:any = {settingType: "default_email"}

    const [openNewBlockList, setOpenNewBlockList] = useState(false);

    const [blockListPayload, setBlockListPayload] = useState<any>(null);
    const [originalBlockListPayload, setOriginalBlockListPayload] = useState<any>(null);

    useEffect(()=> {
        setChangeStatus(props.status)
    }, [props.status])

    useEffect(()=> {
        const sender:any = props?.emailData?.from ? [props?.emailData?.from] : []
        const file_hashes:any = []
        const urls:any = []
        if(props?.emailData?.attachments?.length > 0) {
            props?.emailData?.attachments.map((file:any) => {
                if(file_hashes.indexOf(file['hash_code']['sha256'])===-1) {
                    file_hashes.push(file['hash_code']['sha256'])
                }
                return file
            })
        }
        if(props?.emailData?.urls?.length > 0) {
            props?.emailData?.urls.map((url:any)=> {
                if(urls.indexOf(url['value']) === -1) {
                    urls.push(url['value'].replace(/^https?:\/\//, ''))
                }
                return url
            })
        }

        const emailPayload:any = {
            senders: sender,
            urls: urls,
            file_hashes: file_hashes
        }

        setOriginalBlockListPayload(emailPayload);
    }, [props.emailData])


    const handleChangeAndUpdateInbox = (key: string, value:string) => {
        const row = rowParams(emailID, {[key]: value})
        updateInboxMessage(row).then((data) => {
            props.handleEmailChanged()
        }).catch(() => {})
    }

    const setDropdownListData = (dataList: object, handleOnClick: any, color?: string, parent?: string) => {
        let dtList: any = [];
        Object.keys(dataList).forEach((key) => {
            dtList.push({
                parent: parent,
                text: dataList[key]['label'] || dataList[key]['text'],
                value: dataList[key]['id'] || dataList[key]['value'] || key,
                customStyle: {
                    hoverBg: dataList[key]['hoverBg'],
                    hoverColor: dataList[key]['hoverColor'],
                    borderRadius: dataList[key]['borderRadius'] || 4
                },
                style: {
                    color: color || dataList[key]['selectColor'] || dataList[key]['color'],
                    background: dataList[key]['background'],
                    fontWeight: 600,
                },
                onClick: handleOnClick
            })
        });
        return dtList;
    }


    const rowParams = (emailID:any,  data: object ) => {
        return Object.assign({
            "emailId": emailID,
            "zoneId": userData.zoneId.toString(),
            "adminId": userData.userId.toString(),
            "actionType": userData.role
        }, data);
    }

    const renderDropdownList = (title: string, dataList: object, displayVariant: any, value: any) => {
        return <Box
          className={'dropdownlist-inbox-action'}
          sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
              height: "100%"
          }}
        >
            <Typography variant={'body2'} sx={{fontWeight: '500'}}>{title}</Typography>
            <DropDownSelect variant={displayVariant} value={value} dataset={dataList} title={title} is_archived={detail.is_archive}/>
        </Box>
    }

    const handleClickOpen = (action?:any) => {
        const content:any = {
            title: 'Send email',
            content: '',
            action: action
        }
        handleGetUsedEmail(dataParams)
        setOpenDialog(true);
        setModalID(modalID + 1)
        setContentModal(content)
        setParamSendMail(() => {
            return ({
                ...defaultParams,
                baseSender: usedEmail && usedEmail.length !== 0 ? usedEmail : "",
                fromName: usedEmail && usedEmail.length !== 0 ? usedEmail?.from_name : "No-Reply",
            })
        })
    };

    const handleClose = () => {
        setOpenDialog(false);
        setValidateFromName(false);
        setValidateEmailContent(false);
        setValidateUserName(false);
        setValidateBaseSender(false);
    };

    const openConvertToMailTemplate = () => {
        setOpenConvertDialog(true);
    };

    useEffect(() => {
        getBlockListsSetting({
            service: 'BLOCK_LIST',
            user: 'pre',
        }).then((response)=>{
            if(!response.data.tenant) { return }
            setBlocklistTenantId(response.data.tenant)
            if(blockListAuthRef && blockListAuthRef.current) {
                blockListAuthRef.current.requestAccessToken().then((accessToken: string) => {
                    handleSyncBlockListEntries()
                }).catch((error: any) => {
                    console.log('Cannot request access token. No user logged in')
                });
            }
        }).catch((error) => {
        })
    }, [])

    const handleSyncBlockListEntries = () => {
        const token:any = sessionStorage.getItem(blockListTokenKey)
        const accessTokenDecoded:any = jwt_decode(token)
        const lastSync = sessionStorage.getItem('lastSync')
        const currentTime = new Date().getTime();
        if(lastSync && (currentTime - parseInt(lastSync)) < 60000){return}
        sessionStorage.setItem('lastSync', currentTime.toString())
        syncBlockListEntries({
            service: 'SYNC_BLOCK_LIST_ENTRIES',
            accessToken: sessionStorage.getItem(blockListTokenKey),
            upn: accessTokenDecoded?.upn
        }).then((response)=>{
            console.log('Done')
        }).catch((error) => {
            console.log('Sync failed')
        })
    }

    const fnAccessGained = (accountInfo:any={}) => {
        setLoadingPage(true)
        acquireBlockListEntries({
            blocklist: originalBlockListPayload,
            action: BLOCK_LIST_API_ACTIONS.VALIDATE_BLOCK_LIST,
            accessToken: sessionStorage.getItem(blockListTokenKey)
        }).then((response:any) => {
            setBlockListPayload(response.data.blocklist)
            setOpenNewBlockList(true)
            setLoadingPage(false)
        }).catch((error) => {
            console.log(error)
            setOpenNewBlockList(false)
            setLoadingPage(false)
        });
    }

    const handleOpenNewBlockList = () => {
        setOnBlockListAccessGained(() => {
            return fnAccessGained
        })
        if(blockListAuthRef && blockListAuthRef.current){
            blockListAuthRef.current.requestAccessToken().then((userInfo:string) => {
                fnAccessGained(userInfo)
            }).catch((error:any) => {
                blockListAuthRef.current.openConfirmModal()
            });
        }
    };

    const handleCloseConvertModal = () => {
        setOpenConvertDialog(false);
        setConvertTemplateData(defaultConvertValues);
    };

    const handleSetConvertError = (responseData:any) => {
        console.log(responseData)
        let message = EXPORT_ERROR_MESSAGES.ERROR_UNKNOWN
        if(responseData.error){
            message = EXPORT_ERROR_MESSAGES[responseData.error]
        }
        setConvertErrorModalContent(message)
    }

    /**
     * handleRunActionRule
     * Action controller
     * @param rule
     */
    const handleRunActionRule = (rule:any) => {
        alert('Click ' + rule + ' on ' + emailID);
    }
    actionRules.forEach((item:any) => {
        item.onClick = handleRunActionRule
    })

    const defaultParams: any = {
        subject: detail.subject || '',
        from: detail.email,
        fromName: 'No-Reply',
        to: [],
        cc: [],
        originalReporter: true,
        statusToResolved: true,
        content: '',
        base64Data: '',
        originalEmail: '',
        recipients: [],
        reportedBy: detail.reportedBy,
        userName:'phriendlyphishing',
        baseDomain:'',
        baseSender:'',
    }

    const drawerBleeding = 0;
    const [isActionShow, setActionShow] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setActionShow(newOpen);
    };

    const [paramSendMail, setParamSendMail] = useState(defaultParams)
    const [usedEmail, setUsedEmail] = useState<any>(null)

    let prioritiesList:any = setDropdownListData(CONSTANTS.PRIORITIES, handleChangeAndUpdateInbox, '#fff', 'priority');
    let statusesList:any = setDropdownListData(CONSTANTS.STATUS, handleChangeAndUpdateInbox, undefined, 'status');
    let categoryList:any = setDropdownListData(categories, handleChangeAndUpdateInbox, '#011111', 'category');

    useEffect(()=> {
        handleGetUsedEmail(dataParams)
    }, [])

    const handleGetUsedEmail = (dataParams:object) => {
        getUsedEmail(dataParams).then((res) => {
            setUsedEmail(res.data)
        }).catch((res) => {
            console.log(res)
        })
    }

    const handleConvertToTemplate = () => {
        handleCloseConvertModal();
        setLoadingPage(true)
        return importToService(convertTemplateData, emailID).then((_) => {
            setLoadingPage(false)
            setOpenConvertCompleteDialog(true);
        }).catch(err => {
            setLoadingPage(false)
            handleSetConvertError(err.response.data)
            setOpenConvertErrorDialog(true);
        });
    };
    const sendEmail:any = (paramSendMail:object) => {
        const changeStatusParams = {
            emailId: emailID,
            status: 'Resolved',
            priority: props.priority,
            category: props.category
        }
        paramSendMail['attMail'] = {'original_email.eml': props.originalEmail};
        paramSendMail['emailId'] = emailID;
        paramSendMail['zoneId'] = zoneID.toString();
        paramSendMail['settingType'] = "default_email";
        paramSendMail['settingValue'] = Boolean(true);
        setOpenDialog(false);
        setActionShow(false);

        return sendMessageEmail(paramSendMail).then((result:any) => {
            setToastValue({type: 'success', message: 'Email sent successfully', isOpen: true, toastId: modalID})
            handleGetUsedEmail(dataParams)
            if(paramSendMail['statusToResolved']){
                return updateTriageInboxes(changeStatusParams).then((result:any) => {
                    setChangeStatus('Resolved')
                });
            }
        });
    };

    const is_archived = window.location.pathname.split('/archived/').length === 2 || detail.is_archive

    const menuData:any = [{
        email_id: emailID,
        email_content: detail
    }];

    const generateActionMenu = () => {
        if (is_archived) {
            return {
                "Delete": DELETE,
            }
        }

        const fullActions:any = {
            "SendMail": SEND_EMAIL,
            "ConvertToMailTemplate": CONVERT_TO_EMAIL_TEMPLATE,
            "Sepline1": '',
            "Purge": PURGE_OPTION,
            "AddToBlockList": ADD_TO_BLOCK_LIST,
            "Sepline2": '',
            "Archive": ARCHIVE,
            "Delete": DELETE,
        }

        if(!setting[SETTING_TYPE_REPORTED_EMAILS_ACTIONS] || !isMS365) {
            delete fullActions["Purge"]
        }

        if(!setting[SETTING_BLOCK_LIST] || !isMS365){
            delete fullActions["AddToBlockList"]
        }

        if(fullActions.hasOwnProperty('AddToBlockList') && !fullActions.hasOwnProperty('Purge')) {
            delete fullActions["Sepline1"]
            delete fullActions["Sepline2"]
        }

        return fullActions
    }
    const actionOtherData:any = generateActionMenu()

    const validatePopupSendMail = () => {
        const editorValue = document.getElementsByClassName("jodit-wysiwyg");

        if(editorValue){
            paramSendMail['content'] = editorValue[0].innerHTML;
        }
        if(!paramSendMail['fromName'].trim() ||
            paramSendMail[ 'to' ].length === 0||
            !paramSendMail[ 'content' ] ||
            !paramSendMail[ 'content' ]?.replace(/<\/?[^>]+(>|$)/g, "") ||
            !paramSendMail['userName'].trim() ||
            !paramSendMail['baseSender']?.mail_sender_id) {
            if(!paramSendMail['fromName'].trim()) {
                setValidateFromName(true);
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            }
            if(!paramSendMail['userName'].trim()) {
                setValidateUserName(true);
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            }
            if(!paramSendMail['baseSender']?.mail_sender_id) {
                setValidateBaseSender(true);
                setModalID(modalID + 1);
                setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
            }
            if(paramSendMail[ 'to' ].length === 0) {
                setValidateToField(true);
                setModalID(modalID + 1);
                setToastValue({
                    type: 'error',
                    message: 'Please complete the required fields.',
                    isOpen: true,
                    toastId: modalID
                });
            }
            if (!paramSendMail[ 'content' ] || !paramSendMail[ 'content' ]?.replace(/<\/?[^>]+(>|$)/g, "")){
                setValidateEmailContent(true);
                setModalID(modalID + 1)
                setToastValue({
                    type: 'error',
                    message: 'Please complete the required fields.',
                    isOpen: true,
                    toastId: modalID
                });
            }
        } else {
            sendEmail(paramSendMail);
            handleClose();
            setValidateToField(false);
            setValidateFromName(false);
            setValidateEmailContent(false);
            setValidateUserName(false);
            setValidateBaseSender(false);
        }
    }

    // @ts-ignore
    return (
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Stack spacing={2}>
              <Stack
                style={{backgroundColor: '#f2f3f3'}}
                id='action-mail'>
                  {renderDropdownList('Priority:', prioritiesList, 'caption', props.priority)}
                  {isMobileOnly ? "" : <hr/>}
                  {renderDropdownList('Status:', statusesList, 'body2', changeStatus)}
                  {isMobileOnly ? "" : <hr/>}
                  {renderDropdownList('Category:', categoryList, 'body2', props.category)}
                  {isMobileOnly ? "" : <Divider light />}
              </Stack>
              {isMobileOnly ? <Box
                sx={{
                    boxShadow: '-4px -4px 16px rgba(0, 0, 0, 0.06);',
                    backgroundColor: '#ffffff',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '16px',
                    zIndex: 1

                }}
              >
                  <Box sx={{ textAlign: 'center', pt: 1 }} className={'action-mobile'}>
                      <Button
                        sx={{
                            height: 48,
                            backgroundColor: '#345C8B',
                            justifyContent: 'space-between',
                            color: '#ffffff',
                            "&:hover": {
                                color: '#345C8B',
                            },
                        }}
                        fullWidth={true}
                        onClick={toggleDrawer(true)}
                        endIcon={<ArrowDropUpIcon fill={'#ffffff'}/>}
                      > Action </Button>
                  </Box>
              </Box> : ""}
              {isMobileOnly ? <Box>
                  <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `calc(50% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                            borderTopRightRadius: '16px',
                            borderTopLeftRadius: '16px',
                        },
                    }}
                  />
                  {/*// @ts-ignore*/}
                  <SwipeableDrawer
                    anchor="bottom"
                    open={isActionShow}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        position: 'relative',
                    }}
                  >
                      <Box sx={{
                          padding: '16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderBottom: '1px solid #E0E0E0'
                      }}>
                          <Typography sx={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              color: '#193051',
                              fontWeight: 600
                          }}>
                              Action
                          </Typography>
                          <CloseIcon onClick={toggleDrawer(false)}/>
                      </Box>
                      <DropdownContent
                           menuData={menuData}
                           actionOtherData={actionOtherData}
                           handleOpenSendMail={handleClickOpen}
                           handleDeleteMail={props.handleDelete}
                           handleArchiveMail={props.handleArchiveMail}
                           handlePurgeMail={props.handlePurgeMail}
                           isAuthenticatedAzure={isAuthenticated}
                           loginAzure={props.loginAzure}
                           hasAccessToken={props.hasAccessToken}
                      />

                  </SwipeableDrawer>
              </Box> : <Stack spacing={1}>
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0 0 4px 0',
                    }}>
                      <DropDownList
                        variantDropdown='actions-dropdown'
                        selectedTitle={'ACTIONS'}
                        children={<DropdownContent menuData={menuData}
                                                   actionOtherData={actionOtherData}
                                                   handleOpenSendMail={handleClickOpen}
                                                   handleDeleteMail={props.handleDelete}
                                                   handleArchiveMail={props.handleArchiveMail}
                                                   handlePurgeMail={props.handlePurgeMail}
                                                   handleConvertToMailTemplate={openConvertToMailTemplate}
                                                   handleAddToBlockList={()=> handleOpenNewBlockList()}
                                                   isAuthenticatedAzure={isAuthenticated}
                                                   loginAzure={props.loginAzure}
                                                   hasAccessToken={props.hasAccessToken}
                        />}
                      />
                  </Box>
              </Stack>}

          </Stack>

          <Modal idModal='modal-send-mail'
                 variant='send-mail'
                 disableEnforceFocus={true}
                 fullScreen={isMobileOnly ? true : false}
                 openDialog={openDialog}
                 title={contentModal.title}
                 actionAgree={validatePopupSendMail}
                 actionCancel={handleClose}
                 textAgree={'Send'}
                 textCancel={'Cancel'}
                 onClose={handleClose}
                 handleOnChange={(e:any) => setParamSendMail({
                     ...paramSendMail,
                     'statusToResolved': e.target.checked,
                 })}
          >
              <SendMailModal
                  defaultParams={defaultParams}
                  paramSendMail={paramSendMail}
                  setParamSendMail={setParamSendMail}
                  scanResult={props.scanResult}
                  validateFromName={validateFromName}
                  setValidateFromName={setValidateFromName}
                  fromNameError={'This field is required.'}
                  validateToField={validateToField}
                  setValidateToField={setValidateToField}
                  validateEmailContent={validateEmailContent}
                  setValidateEmailContent={setValidateEmailContent}
                  editorError={'This field is required.'}
                  validateUserName={validateUserName}
                  setValidateUserName={setValidateUserName}
                  validateBaseSender={validateBaseSender}
                  setValidateBaseSender={setValidateBaseSender}
                  isMessageDownload={true}
              />
          </Modal>
          <Modal
            idModal="modal-convert-to-template"
            disableEnforceFocus
            fullScreen={isMobileOnly ? true : false}
            openDialog={openConvertDialog}
            title="Convert with Phlipper"
            actionAgree={handleConvertToTemplate}
            actionCancel={handleCloseConvertModal}
            textAgree={'Convert'}
            textCancel={'Cancel'}
            onClose={handleCloseConvertModal}
          >
              <ConvertToTemplateModal
                convertTemplateData={convertTemplateData}
                setConvertTemplateData={setConvertTemplateData}
                userRole={userData.role}
              />
          </Modal>
          {
              <Fragment ><Modal idModal='modal-new-block-list'
                                        openDialog={openNewBlockList}
                                        variant={'new-block-list-entry'}
                                        title={'Create Block List Entry'}
                                        content={ <AddBlockListModal ref={addBlockListRef} payload={blockListPayload} emailId={emailID}/> }
                                        actionAgree={() => {
                                            if(addBlockListRef && addBlockListRef.current) {
                                                setLoadingPage(true)
                                                addBlockListRef.current.saveBlockList().then(() => {
                                                    setLoadingPage(false)
                                                    setOpenNewBlockList(false)
                                                    setModalID(modalID + 1)
                                                    setToastValue({type: 'success', message: 'Block list created successfully', isOpen: true, toastId: modalID})
                                                }).catch((error:any) => {
                                                    setLoadingPage(false)
                                                    if(error==='Error: Empty') {
                                                        setModalID(modalID + 1)
                                                        setToastValue({type: 'error', message: 'Please select at least one entry.', isOpen: true, toastId: modalID})
                                                    }
                                                })
                                            }
                                        }}
                                        actionCancel={() => {setOpenNewBlockList(false)}}
                                        textAgree={'Save'}
                                                    onClose={() => {setOpenNewBlockList(false)}}
              /></Fragment>
          }

          <Message
            title="Phlipper has started!"
            content={<p className="convert-message-content">
                The process to convert this phish into an email template has started.
                Once finished, it will be available to finalise in the Phriendly Phishing platform under Email Templates {'>'} Drafts.</p>}
            showMessage={openConvertCompleteDialog}
            onConfirm={() => setOpenConvertCompleteDialog(false)}
            icon={<CheckCircle sx={{ fontSize: '35px', color: '#008545', marginLeft: '-2px' }}/>}
          />
          <Message
            title="Failed to convert to a template"
            content={
              <p className="convert-message-content" dangerouslySetInnerHTML={{__html: convertErrorModalContent}} >

              </p>
            }
            showMessage={openConvertErrorDialog}
            onConfirm={() => setOpenConvertErrorDialog(false)}
            icon={<Error sx={{ fontSize: '35px', color: '#C80000', marginLeft: '-2px' }}/>}
            confirmColor="#C80000"
          />
          <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
          <LoadingPage open={loadingPage}/>
          <Ms365BlockListAuth
              ref={blockListAuthRef}
              accessGained={()=> {}}
              tenant={blocklistTenantId}
              onBeforeGetUser={setLoadingPage}
              onAfterGetUser={setLoadingPage}
              userData={userData}
              accessGainedSuccess={onBlockListAccessGained}
          />
      </Box>
    )
}
