import React from 'react';
import {ButtonProps} from './Button.props';
import './Button.styles.css';
import {Image} from '../Image';
import {Text} from '../Text';

export const Button: React.FC<ButtonProps> = ({
  cssClass,
  /* istanbul ignore next */
  actived= false,
  /* istanbul ignore next */
  variant='button-style',
  /* istanbul ignore next */
  fontSize = 14,
  /* istanbul ignore next */
  color= '#fff',
  /* istanbul ignore next */
  width= '216px',
  /* istanbul ignore next */
  height= '48px',
  /* istanbul ignore next */
  backgroundColor= '',
  /* istanbul ignore next */
  border = 2,
  /* istanbul ignore next */
  borderRadius= 'none',
  title,
  /* istanbul ignore next */
  linkIcon,
  onClick,
  fontWeight,
  margin,
  /* istanbul ignore next */
  titleMargin = '0 10px',
  txtWidth,
  textAlign,
  iconMargin,
  typeBtn,
  /* istanbul ignore next */
  iconWidth = '19px',
  /* istanbul ignore next */
  iconHeigth= '19px',
  component,
  tooltipComponent,
  disabled,
  dataTestId,
  ...rest

}) => {
  return (
    <button type={typeBtn} onClick={onClick} className={`button ${variant} ${cssClass} ${/* istanbul ignore next */actived?'active':''}`} style={{margin, width, height, backgroundColor, border, borderRadius,...rest}} {...rest} disabled={disabled} data-testid={dataTestId} >
      {/* istanbul ignore next */linkIcon && <Image width={iconWidth} height={iconHeigth} margin={`${iconMargin}`} srcImg={linkIcon} />}
      {component}
      {/* eslint-disable-next-line react/no-children-prop */}
      {title && <Text textAlign={textAlign} children={title} fontSize={fontSize} margin={titleMargin} width={txtWidth} color={color} fontWeight={`${fontWeight}`}/>}
      {tooltipComponent}
    </button>
  );
};
