import React, {useEffect, useState} from 'react'
import {CredentialCardProps} from "./CredentialCard.props";
import {Box, styled, Tooltip, Typography, useTheme} from "@mui/material";
import {useTranslation, Trans} from "react-i18next";

import Button from "@mui/material/Button";
import './CredentialCard.styles.css';
import {AlertMessage} from "../../atoms/AlertMessage";
import {SyncedStatus} from "../../atoms/SyncedStatus";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import {authorizeToken, updateSetting} from "../../../api/emailTriage";
import {Modal} from "../../atoms/Modal";
import moment from "moment-timezone";

const BoxWrapper = styled('div')(({ theme }) => ({
    width: '50%',
    maxWidth: '700px',
    fontSize: '14px'
}));

const DivWrapper = styled('div')(({ theme }) => ({
    borderStyle: 'solid',
    borderColor: theme.palette.border.main,
    borderRadius: theme.shape.borderRadius,
    borderWidth: '1px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
}));


const SepLine = styled('div')(({ theme }) => ({
    borderStyle: 'solid',
    borderTopColor: theme.palette.border.main,
    borderWidth: '0px',
    borderTopWidth: '1px',
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const OutlineButton = styled(Button)(({ theme }) => ({
    color: '#345C8B',
    borderColor: '#CCCFCF',
    minHeight: "40px",
     "&:hover": {
        borderColor: theme.palette.border.main
    },
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#345C8B',
    color: "white",
    borderColor: '#CCCFCF',
}));

const serviceMessages = (service_name:any) => {
    if(service_name=='auto_block_list'){
        return <>
            When you remove, your Microsoft 365 account will be logged out. Are you sure you want to remove your account from Block List?
            <Box className={'info-box'} sx={{marginTop: 2}}>
                <p>
                    Block List feature enabled will be <strong>disabled</strong>.
                </p>
                <p><strong>If this feature is the last one connected to the tenant</strong>, disabling
                    it <strong>disconnects</strong> the tenant
                </p>
            </Box>
        </>
    } else if(service_name=='auto_phish_clear'){
        return <>
            When you remove, your Microsoft 365 account will be logged out. Are you sure you want to remove your account from Phish Clear?
            <Box className={'info-box'} sx={{marginTop: 2}}>
                <p>
                    Phish Clear feature enabled will be <strong>disabled</strong>.
                </p>
                <p><strong>If this feature is the last one connected to the tenant</strong>, disabling
                    it <strong>disconnects</strong> the tenant
                </p>
            </Box>
        </>
    }
}

export const CredentialCard: React.FC<CredentialCardProps> = ({
    payload = {
        service_key: "",
        service_id: "auto_block_list",
        category: undefined,
        authenticated: false,
        loggedTitle: "True",
        notLoggedTitle: "true",
        accountName: "",
        accountEmail: "",
        loginAt: "",
        remainingDays: 0,
        bodyContent: "",
        disableBtn:false
    },
    onRemoveCredential = () => {},
    onLoggedIn = () => {},
}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme()
    const [showMessage, setShowMessage] = useState(false)

    const serviceMessage = serviceMessages(payload['service_id'])
    //@ts-ignore
    const tz_name = JSON.parse(localStorage.getItem('userData'))["tz_name"] || 'Etc/UTC';
    const onClickLogin = () => {
        onLoggedIn()
    }

    const onClickRemoveCredential = () => {
        setShowMessage(false)
        onRemoveCredential();
    }

    return <>
      <BoxWrapper className={'credential-card-wrapper'}>
        <DivWrapper>
            <div className="credential-card-title">
                <Box className="credential-card-title-left" sx={{fontSize: "16px"}}>
                    <div dangerouslySetInnerHTML={{__html: payload['title']}}></div>
                </Box>
                <div className="credential-card-title-right">
                    <Tooltip
                                placement={"top"} title={t("credentialCard.connected_tenant_tooltip")}
                                componentsProps={{tooltip: {sx:{maxWidth: "450px"}} }}
                            >
                                <SyncedStatus   value={payload['authenticated'] ? "Connected to MS 365 tenant " : "Not connected to MS 365 tenant"}
                                                            icon={payload['authenticated'] ? <CheckCircleIcon /> : <TimerOffOutlinedIcon htmlColor={'#434F4F'}/>}
                                                            valueColor={payload['authenticated'] ? "#008545" : "#434F4F"} />
                            </Tooltip>
                </div>
            </div>
            <SepLine />
            <div className="credential-card-body">
                <div className="credential-card-body">
                    {
                        payload['authenticated'] ?
                            <div>
                                <AlertMessage content={
                                    <>
                                        <Typography sx={{marginBottom: theme.spacing(1), fontSize: "14px", fontWeight: "700", textTransform: 'uppercase'}}>{t("credentialCard.re_login_title")}</Typography>
                                        <Typography variant="body2"
                                                    sx={{marginBottom: theme.spacing(1), fontWeight: "500"}}>
                                            <div
                                                dangerouslySetInnerHTML={{__html: t("credentialCard.login_on_message",
                                                        {date: moment.utc(payload["loginAt"]*1000).tz(tz_name).format('DD/MM/YYYY')})
                                                }}>
                                            </div>
                                        </Typography>
                                    </>
                                } severity={"info"}/>
                                <Box sx={{marginBottom: '8px'}}>
                                {t("credentialCard.login_as")}:
                                </Box>
                                <Box sx={{marginBottom: '16px', fontWeight: 700}}>
                                    <i>{payload['accountEmail']} ({payload['accountName']})</i>
                                </Box>
                                <Typography sx={{marginBottom: '16px', fontSize: '12px', color: '#666666', fontWeight: 500}}>
                                    <div dangerouslySetInnerHTML={{__html: t("credentialCard.note_message"+"_"+payload["service_key"])}}></div>
                                </Typography>
                                {!payload['disableBtn'] && <OutlineButton data-testid={'remove_credential'} variant="outlined" onClick={ () => {setShowMessage(true)}}>
                                    {t("credentialCard.remove_credential")}
                                </OutlineButton>}
                            </div>
                            :
                            <div>
                                <Box sx={{marginBottom: '16px'}}>
                                    <div dangerouslySetInnerHTML={{__html: t("credentialCard.unauthenticated_"+payload['service_id'])}}></div>
                                </Box>
                                {!payload['disableBtn'] && <PrimaryButton data-testid={'common_login'} variant={"contained"} onClick={() => onClickLogin()}>
                                    {t("common.login")}
                                </PrimaryButton>}
                            </div>
                    }
                </div>
            </div>
        </DivWrapper>
        <Modal idModal="modal-setting-off-click"
               data-testid={'modal-setting-off-click'}
               openDialog={showMessage}
               variant={"delete"}
               title={"Remove account"}
               actionAgree={() => onClickRemoveCredential()}
               actionCancel={() => setShowMessage(false)}
               textAgree={"Save changes & Remove"}
               onClose={() => setShowMessage(false)}
               children={serviceMessage}
                    />
      </BoxWrapper>
    </>
}
