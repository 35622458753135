import React, {useRef, useEffect, Fragment} from 'react';

import { SenderAnalysisProps } from './SenderAnalysis.props';
import './SenderAnalysis.styles.css'
import Box from "@mui/material/Box";
import {AnalysisBoxItem} from "../AnalysisBoxItem";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import {AnalysisRiskBox} from "../AnalysisRiskBox/AnalysisRiskBox";
import {LabelIcon} from "../../atoms/LabelIcon";

export const SenderAnalysis: React.FC<SenderAnalysisProps>= ({
    payload = {}
}) => {
  const items:any = payload.items
  const theme = useTheme();
    console.log(payload)
  return <Box sx={{py:4, px: 5}} className={'app-box-shadow-md'}>
      <Grid container rowSpacing={{xs: 3}}>
          <Grid item xs={12} xl={3.3}>
              <Box sx={{mb: 2}} className={'app-flex-horizontal flex-space-between'}>
                  <Typography noWrap  variant={'h6'} className={'app-default-header'}>
                      Sender information
                  </Typography>
                  {/*<LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>*/}
              </Box>
              <Divider sx={{background: theme.palette.ocean_blue.main, mb: 1, borderWidth: 1}} />
              <AnalysisRiskBox payload={{label: 'Sender Display Name', value: payload.name.value || 'N/A', priority: payload.name.priority}} />
              <Divider sx={{background: theme.palette.ocean_blue.main,}} />
              <AnalysisRiskBox payload={{label: 'Sender Email Address', value: payload.email.value || 'N/A', priority: payload.email.priority, score: payload.email.score }} />
              <Divider sx={{background: theme.palette.ocean_blue.main}} />
              <AnalysisRiskBox payload={{label: 'Sender Domain', value: payload.domain.value || 'N/A', priority:payload.domain.priority, score: payload.domain.score }} />
          </Grid>
          <Grid item xl={1} display={{xs: "none", xl: "block" }}>
              <Box className={"sender-analysis-divider"}></Box>
          </Grid>
          <Grid item xs={12} xl={3.3}>
              <Box sx={{mb: 2}} className={'app-flex-horizontal flex-space-between'}>
                  <Typography noWrap  variant={'h6'} className={'app-default-header'}>
                      IP information
                  </Typography>
                  {/*<LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>*/}
              </Box>
              <Divider sx={{background: theme.palette.ocean_blue.main, mb: 1, borderWidth: 1}} />
              <AnalysisRiskBox payload={{label: 'IPv4', value: payload.ipv4.value || 'N/A', priority: payload.ipv4.priority, score: payload.ipv4.score }} />
              <Divider sx={{background: theme.palette.ocean_blue.main,}} />
              <AnalysisRiskBox payload={{label: 'IPv6', value: payload.ipv6.value || 'N/A', priority:payload.ipv6.priority, score: payload.ipv6.score }} />
              <Divider sx={{background: theme.palette.ocean_blue.main}} />
              <AnalysisRiskBox payload={{label: 'IP Location', value: payload.ip_location?.value, priority:payload.ip_location?.priority }} />
          </Grid>
          <Grid item xl={1} display={{xs: "none", xl: "block" }}>
              <Box className={"sender-analysis-divider"}></Box>
          </Grid>
          <Grid item xs={12} xl={3.4}>
              <Box sx={{mb: 2}} className={'app-flex-horizontal flex-space-between'}>
                  <Typography noWrap  variant={'h6'} className={'app-default-header'}>
                      Delivery information
                  </Typography>
                  {/*<LaunchIcon fontSize={"medium"} htmlColor={"#193051"}/>*/}
              </Box>
              <Divider sx={{background: theme.palette.ocean_blue.main, mb: 1, borderWidth: 1}} />
              <AnalysisRiskBox payload={{
                  content: <LabelIcon
                      text={payload.dmarc.value == 'pass' ? "Pass" : "Fail"}
                      icon={payload.dmarc.value == 'pass' ? "success":"error"}
                      variant={payload.dmarc.value == 'pass' ? "success":"error"} />,
                  label: 'DMARC Compliant', value: payload.dmarc.value, priority: payload.dmarc.value == 'pass' ? "":"high" }} />
              <Divider sx={{background: theme.palette.ocean_blue.main,}} />
              <AnalysisRiskBox payload={{
                  content: <LabelIcon
                      text={payload.spf.value == 'pass' ? "Pass" : "Fail"}
                      icon={payload.spf.value == 'pass' ? "success":"error"}
                      variant={payload.spf.value == 'pass' ? "success":"error"} />,
                  label: 'SPF', value: payload.spf.value, priority: payload.spf.value == 'pass' ? "":"high" }} />
              <Divider sx={{background: theme.palette.ocean_blue.main}} />
              <AnalysisRiskBox payload={{
                  content: <LabelIcon
                      text={payload.dkim.value == 'pass' ? "Pass" : "Fail"}
                      icon={payload.dkim.value == 'pass' ? "success":"error"}
                      variant={payload.dkim.value == 'pass' ? "success":"error"} />,
                  label: 'DKIM', value: payload.dkim.value, priority: payload.dkim.value == 'pass' ? "":"high" }} />
          </Grid>
      </Grid>
  </Box>
};
