import { ThemeOptions} from '@mui/material/styles';
import {Colors} from "../configs/colors";

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    black_darker: Palette['primary'];
    ocean_blue: Palette['primary'];
    blue: Palette['primary'];
    border: Palette['primary'];
    glazed_black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    black_darker?: PaletteOptions['primary'];
    ocean_blue?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    border?: PaletteOptions['primary'];
    glazed_black?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    black: true;
  }
}

const LightTheme: ThemeOptions = {
  palette: {
    black: {
      light: '#808888',
      main: '#011111',
      dark: '#4D5858',
    },
    black_darker: {
      light: '#808888',
      main: '#011111',
      dark: '#4D5858',
    },
    secondary: {
      main: '#467599'
    },
    error: {
      main: "#B00020"
    },
    primary: {
      main: "#193051",
      dark: "#193051"
    },
    ocean_blue: {
      main: '#9FD8DB'
    },
    blue: {
      main: '#03A9F5'
    },
    warning: {
      main: '#FCAB10'
    },
    border: {
      main: '#E0E0E0'
    },
    glazed_black: {
      main: '#434F4F'
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: 14,
    },
    fontSize: 14,
    fontFamily: [
      "Montserrat",
      "Roboto",
      "Arial",
      "sans-serif"
    ].join(","),
    subtitle2: {
      fontSize: 12,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: 16,
          "& .MuiChip-labelSmall": {
            padding: 4,
            fontSize: 12
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            fontWeight: 600
          },
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
          fontSize: 12,
          lineHeight: 1.5
        }
      }
    },
    MuiList: {
      // styleOverrides: {
      //   root: {
      //     borderRadius: 4,
      //     boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      //     border: '1px solid #CCCFCF'
      //   }
      // }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          paddingRight: 30,
          "&.Mui-selected": {
            // backgroundColor: '#DFF4ED'
          },
        },

      }
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          '&.app-ui': {
            color: "#193051",
            fontFamily: [
              "Righteous",
              "Montserrat",
              "Roboto",
              "Arial",
              "sans-serif"
            ].join(","),
          },
        },
        h6: {
          '&.app-ui': {
            color: "#193051",
            fontFamily: [
              "Righteous",
              "Montserrat",
              "Roboto",
              "Arial",
              "sans-serif"
            ].join(","),
          },
          '&.app-light-header': {
            color: "#345C8B",
            fontWeight: 400,
            fontFamily: [
              "Montserrat"
            ].join(",")
          },
          '&.app-default-header': {
            color: "#193051",
            fontWeight: 700,
            fontFamily: [
              "Montserrat"
            ].join(",")
          },
        },
        body1: {
          '&.app-label': {
            color: "rgba(0,0,0,0.6)"
          }
        },
        body2: {
          '&.app-semi-bold': {
            fontWeight: 600,
          },
          '&.app-label': {
            color: "rgba(0,0,0,0.6)"
          }
        }
      },
    },
  }
}

export default LightTheme;
