import React, {useContext, useEffect, useState} from 'react';
import { SettingsProps } from './Settings.props';
import './Settings.styles.css';
import { PageHeader } from '../../components/organisms/PageHeader';
import { SettingCard } from '../../components/molecules/SettingCard';
import {Box, Theme, useTheme} from "@mui/material";
import {authorizeToken, getSettings, updateSetting} from "../../api/emailTriage";
import {
    ADMIN_ROLE,
    SETTING_TYPE_REPORTED_EMAILS_ACTIONS,
    SETTING_TYPE_AUTOMATION_RULE,
    SETTING_BLOCK_LIST
} from "../../constants/contants";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import {BaseDomainSetting} from "../../components/organisms/BaseDomainSetting";
import {GlobalContext} from "../../context/GlobalState";
import {CredentialCard} from "../../components/molecules/CredentialCard";
import {useTranslation} from "react-i18next";
import {MicrosoftTenantConnectionSetting} from "../../components/organisms/MicrosoftTenantConnectionSetting";
import {getNestedProperty, getUserData} from "../../utils/common";
import Skeleton from "@mui/material/Skeleton";
import {LoadingPage} from "../../components/organisms/LoadingPage";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment-timezone";

const navigationLinks = [
  {
    name: 'Settings'
  }
]

const LoginAzure = (params:any) => {
    const currentUser:any = getUserData()
    const payload:any = {}

    payload["sourceId"] = params['sourceId']
    payload["service"] = params['service']
    payload["identity_action"] = "login"
    payload["app_id"] = process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID
    payload["redirect_uri"] = window.location.origin
    payload["user_id"] = currentUser['id']
    payload["user_role"] = currentUser['role']
    payload["user_email"] = currentUser['email']
    payload["user_name"] = currentUser['name']

    sessionStorage.setItem("auth_route", JSON.stringify({
                    service: params['service'],
                    id: params['sourceId']
                }))

    authorizeToken(payload).then((response) => {
        console.log('LoginAzure response', response)
        window.open(
            response.data.authorization_url,
            '_self', 'noopener,noreferrer'
        )
    })
    .catch(e => {
        console.log(e);
    })
  }

const generateAutomationComponent = (config:any, theme:any, t:any) => {

    let autoPhishClearSetting:any = config['auto_phish_clear']

    const autoPhishClear:any = <CredentialCard payload={{
            service_key: 'phish_clear',
            service_id: 'auto_phish_clear',
            title: t("credentialCard.phish_clear_title"),
            authenticated: autoPhishClearSetting['authenticated'],
            accountName: autoPhishClearSetting['account_name'],
            accountEmail: autoPhishClearSetting['account_email'],
            loginAt: autoPhishClearSetting['login_at'],
            lastUpdate: autoPhishClearSetting['updated_at']
        }}
            onRemoveCredential={()=> {
                    config['setPageLoading'](true)
                    updateSetting({
                        task: 'disconnect_auto_phish_clear',
                    }).then((response) => {
                        config['onRemoveCredential']()
                    }).catch(e => {
                        config['setPageLoading'](false)
                    })
                }
            }
            onLoggedIn={() => {
                config['onBeforeLogin']()
                LoginAzure({
                    service: "setting_auto_phish_clear",
                    sourceId: ""
                })
            }}
        ></CredentialCard>

    let autoBlocklistSetting:any = config['auto_block_list']
    const autoBlockList = <CredentialCard payload={{
            service_key: 'block_list',
            service_id: 'auto_block_list',
            title: t("credentialCard.block_list_title"),
            authenticated: autoBlocklistSetting['authenticated'],
            accountName: autoBlocklistSetting['account_name'],
            accountEmail: autoBlocklistSetting['account_email'],
            loginAt: autoBlocklistSetting['login_at'],
            lastUpdate: autoBlocklistSetting['updated_at']
        }}
            onRemoveCredential={()=> {
                    config['setPageLoading'](true)
                    updateSetting({
                        task: 'disconnect_auto_block_list',
                    }).then((response) => {
                        config['onRemoveCredential']()
                    }).catch(e => {
                        config['setPageLoading'](false)
                    })
                }
            }
            onLoggedIn={() => {
                config['onBeforeLogin']()
                LoginAzure({
                    service: "setting_auto_block_list",
                    sourceId: ""
                })
            }}
        ></CredentialCard>

    return <Box sx={{gap: 2, display: 'flex', width: '100%', borderTop: `1px solid ${theme.palette.border.main}`, marginTop: 2, paddingTop: 2}}>
        {autoPhishClear}
        {autoBlockList}
    </Box>
}


export const SettingFull: React.FC<SettingsProps> = () => {
  const [statusSettings, setStatusSetting] = useState<any>({})
  const [loadingPage, setLoadingPage] = useState(false)
  const [loadingFullPage, setLoadingFullPage] = useState(false)
  const [roleUser, setRoleUser] = useState('')
  const localStorageUserData = localStorage.getItem('userData');
  const [tenantData, setTenantData] = useState<any>(null)
  const [accountsData, setAccountsData] = useState<any>({})

  const { globalState } = useContext(GlobalContext);
  const isMS365 = globalState.ms365
  const theme = useTheme()
  const { t, i18n } = useTranslation();
  const [automationComponents, setAutomationComponents] = useState<any>(<></>)

  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as {
      service?: string
      message?: string
      status?: boolean
      sourceId?: string
  };

  const currentUser = getUserData()

  const reloadCredentials = () => {
    getTenantData();
    getAccountsData(true)
  }

  const getTenantData = () => {
    setTenantData(undefined)

    getSettings({
        'task': 'get_tenant_data',
        'user_id': currentUser['id'],
        'user_email': currentUser['email'],
        'user_role': currentUser['role']
    }).then((response) => {
      const responseData:any = response.data
      setTenantData(responseData)
    })
  }


  const getAccountsData = (showLoading=false) => {
      if(showLoading){
          setLoadingFullPage(true)
      }
    getSettings({
        'task': 'get_accounts_data',
        'user_id': currentUser['id'],
        'user_email': currentUser['email'],
        'user_role': currentUser['role']
    }).then((response) => {
      const responseData:any = response.data
      const payload = responseData
      payload['onRemoveCredential'] = () => { reloadCredentials() }
      payload['onBeforeLogin'] = () => { setLoadingFullPage(true) }
      payload['setPageLoading'] = (value:boolean) => { setLoadingFullPage(value) }
      setAutomationComponents(generateAutomationComponent(payload, theme, t))
      setAccountsData(responseData)
        if(showLoading){
            setLoadingFullPage(false)
        }
    })
  }

  useEffect(() => {
    if(localStorageUserData) {
      const parseUserData = JSON.parse(localStorageUserData)
      setRoleUser(parseUserData["role"])
    }
    setLoadingPage(true)
    getTenantData()
    getAccountsData()
    getSettings().then((response) => {
      const data:any = response.data
      const statuses = {}
      for(let i = 0; i < data.length; i++) {
        Object.assign(statuses, {[data[i]['setting_type']]: data[i]['setting_value']})
      }
      setStatusSetting(statuses)
      setLoadingPage(false)
    })
  },[])

    useEffect(() => {
        if (locationState) {
          navigate('.', { replace: true, state: null });
        }
    }, [locationState, navigate]);

    return (
        <Box className='setting-page page-content-wrapper'>
            <PageHeader pageTitle={navigationLinks[0].name}/>
            <Box className='page-content-body'>
                {
                    isMS365 && tenantData ? <MicrosoftTenantConnectionSetting payLoad={{
                        tenantId: tenantData['tenantId'],
                        featureListInfo: [
                            {title: "Automation Phish Clear", connectionStatus: tenantData['automationPhishClear']},
                            {title: "Automation Block List", connectionStatus: tenantData['automationBlocklist']},
                            {title: "Manual Phish Clear", connectionStatus: tenantData['phishClear']},
                            {title: "Manual Block List", connectionStatus: tenantData['blocklist']}
                        ]
                    }}
                    onBeforeDisconnect = {() => { setLoadingFullPage(true) }}
                    onAfterDisconnected = {() => {setLoadingFullPage(false)}}
                    onDisconnected = {()=> {reloadCredentials()}}
                    /> : <></>
                }

                { isMS365 &&  <SettingCard
                            loading={loadingPage}
                            title="Automation Rules"
                            description="Enable automation rules functionality"
                            status={statusSettings[SETTING_TYPE_AUTOMATION_RULE]}
                            type={SETTING_TYPE_AUTOMATION_RULE}
                            component = {automationComponents}
                            showDisableDialog={
                                getNestedProperty(accountsData, ['auto_block_list','authenticated'], false) ||
                                getNestedProperty(accountsData, ['auto_phish_clear','authenticated'], false)
                            }
                            disableDialogTitle={"Disable Automation Rules"}
                            disableDialogContent={<>
                                When you disable, your Microsoft 365 account will also be logged out. Are you sure you want to disable the Automation Rules?
                                <Box className={'info-box'} sx={{marginTop: 2}}>
                                     <strong>If this feature is the last one connected to the tenant</strong>, disabling it <strong>disconnects</strong> the tenant
                                </Box>
                            </>}
                            onDisabled={() => {
                                updateSetting({
                                    task: 'disconnect_auto_block_list',
                                }).then((response) => {
                                    updateSetting({
                                        task: 'disconnect_auto_phish_clear',
                                    }).then((response) => {
                                        reloadCredentials()
                                    }).catch(e => {
                                        reloadCredentials()
                                    })
                                }).catch(e => {
                                    reloadCredentials()
                                })

                            }}
                        /> }

                { isMS365 && <SettingCard
                            loading={loadingPage}
                            title="Actions for reported emails"
                            description="Enable Phish Clear functionality"
                            status={statusSettings[SETTING_TYPE_REPORTED_EMAILS_ACTIONS]}
                            type={SETTING_TYPE_REPORTED_EMAILS_ACTIONS}
                            component = {<></>}
                        /> }

                { isMS365 && <SettingCard
                            loading={loadingPage}
                            title="Block list"
                            description="Enable Block List functionality"
                            status={statusSettings[SETTING_BLOCK_LIST]}
                            type={SETTING_BLOCK_LIST}
                            component = {<></>}
                        />
                }

                {roleUser === ADMIN_ROLE ? <BaseDomainSetting/> : null}
            </Box>
            <LoadingPage open={loadingFullPage}/>
        </Box>
    );
};

export const Settings = ChangeWithZone(SettingFull);
