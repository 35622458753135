import React, {useEffect} from 'react';
import './AuthenAzure.style.css';
import {AuthenAzureProps} from './AuthenAzure.props';
import {LoadingPage} from "../../components/organisms/LoadingPage";
import {authorizeToken} from "../../api/emailTriage";
import {useNavigate, useSearchParams} from "react-router-dom";
import {STATUS_CODE} from "../../constants/contants";
import {getUserData} from "../../utils/common";

const SERVICE_ROUTE:any = {
  "setting_block_list": {
    "service_key": "block_list",
    "path": "/settings",
    "name": "setting_block_list"
  },
  "setting_phish_clear": {
    "service_key": "phish_clear",
    "path": "/settings",
    "name": "setting_phish_clear"
  },
  "setting_auto_block_list": {
    "service_key": "auto_block_list",
    "path": "/settings",
    "name": "setting_auto_block_list"
  },
  "setting_auto_phish_clear": {
    "service_key": "auto_phish_clear",
    "path": "/settings",
    "name": "setting_auto_phish_clear"
  },
  "inbox_block_list": {
    "service_key": "block_list",
    "path": "/settings",
    "name": "inbox_block_list"
  },
  "inbox_phish_clear": {
    "service_key": "phish_clear",
    "path": "/settings",
    "id": "",
    "name": "inbox_phish_clear"
  },
  "auto_block_list": {
    "service_key": "auto_block_list",
    "path": "/automation-rules",
    "id": "",
    "name": "auto_block_list"
  },
  "auto_phish_clear": {
    "service_key": "auto_phish_clear",
    "path": "/automation-rules",
    "id": "",
    "name": "auto_phish_clear"
  },
  "inbox_details_block_list": {
    "service_key": "block_list",
    "path": "/settings",
    "id": "",
    "name": "inbox_details_block_list"
  }
}

const getAuthSession = (sessionName:any) => {
  const session_data = sessionStorage.getItem(sessionName)
  if(session_data){
    sessionStorage.removeItem("sessionName")
    return JSON.parse(session_data)
  } else {
    sessionStorage.removeItem("sessionName")
    return {}
  }
}


export const AuthenAzure: React.FC<AuthenAzureProps> = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const currentUser = getUserData()
  const authSession:any = getAuthSession('auth_route')
  const authCode:string|null = searchParams.get('code')

  useEffect(() => {
    const serviceName:string = authSession["service"]

    const redirectUri = window.location.origin
    const appId = process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID
    const serviceKey:string = SERVICE_ROUTE[serviceName]["service_key"]
    const sourceId:string = authSession["id"]
    const sourcePath = sourceId ? `${SERVICE_ROUTE[serviceName]["path"]}/${sourceId}` :
        SERVICE_ROUTE[serviceName]["path"]

    const params = {
      "service": serviceName,
      "service_key": serviceKey,
      "app_id": appId,
      "auth_code": authCode,
      "redirect_uri": redirectUri,
      "user_id": currentUser['id'],
      "user_role": currentUser['role'],
      "user_email": currentUser['email'],
      "user_name": currentUser['name'],
      "identity_action": "require_token",
      "source_id": sourceId
    }

    authorizeToken(params).then((response) => {
      if(response.status === STATUS_CODE.OK){
        console.log("response> ", response)
        navigate(sourcePath, {
            state: {
              service: serviceName,
              sourceId: sourceId,
              message: response.data.auth_status,
              status: response.data.auth_status
            }
          })
      }
    }).catch(e => {
      console.log(e)
      navigate(sourcePath, {
            state: {
              service: serviceName,
              sourceId: sourceId,
              message: e.message,
              status: false
            }
          })
    })
  }, [navigate]);

  return (
    <><LoadingPage open={true}/></>
  );
};
