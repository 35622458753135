import React from 'react'
import {MiniDashboardProps} from './MiniDashboard.props'
import './MiniDashboard.styles.css';

import {MiniDashboardCard} from "../../molecules/MiniDashboardCard";

import Grid from '@mui/material/Grid';



export const MiniDashboard: React.FC<MiniDashboardProps> = ({
  miniDashboardData,
  isLoading
}) => {
  return (
    <Grid container spacing={2.5}>
      {miniDashboardData?.map((data: any) => (
        <Grid key={data.name} item xs={12} sm={6} md={3}>
          <MiniDashboardCard
            cardName={data.name}
            monthsData={data.months}
            isLoading ={isLoading}
          />
        </Grid>
      ))}
    
    </Grid>
  )
  
}
