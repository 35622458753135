import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import './ConfirmDialog.styles.css';
import {ConfirmDialogProps} from './ConfirmDialog.props';

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
     showDialog,
     onCancel,
     onSubmit,
     children,
     isHidden = false
 }) => {
    return (
        <div style={{maxWidth: '560px'}}>
            <Dialog
                open={showDialog}
                className={'confirm-page-dialog'}
                data-testid={'confirmDialog'}
            >
                <DialogTitle id="confirm-page-title" sx={{padding:'24px 24px 16px 24px'}}>
                    <InfoIcon sx={{ margin: ' 0 16px -10px 0', fontSize: '35px', color: '#467599'}} />
                    <span style={{fontSize: '20px', fontWeight: 700}}>Redirect to Microsoft 365 login</span>
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{padding: '0 24px 24px 24px'}}>
                    <DialogContentText id="confirm-page-description" sx={{fontSize:'16px', fontWeight: 500, color:'#212121'}}>
                      {children}
                    </DialogContentText>
                </DialogContent>
              {!isHidden && <DialogActions sx={{padding: '0 24px 24px 24px'}}>
                    <Button onClick={onCancel} variant="outlined" size={"large"} style={{fontSize:'14px'}}>Cancel</Button>
                    <Button data-testid={'btn-save-365'} onClick={onSubmit} variant="contained" size={"large"} style={{backgroundColor: '#345C8B', fontSize:'14px'}}>
                      Save changes and Login
                    </Button>
                </DialogActions>}
            </Dialog>
        </div>
    )
}
export default ConfirmDialog;